// Variables (matching your existing theme)
$dark: rgb(2, 27, 25);
$primary-dark: rgb(1, 51, 51);
$primary-dark2: rgb(1, 51, 51);
$primary-medium: rgb(28, 126, 116);
$primary-light: rgb(50, 203, 203);
$primary-superlight: #00444535;
$primary-yellow: #ffd800;
$medium: #33cbcb;
$light: #1c7c7c;
$lighter: #d4f1f4;
$vintagewash: #f7efe5;
$blue: #015a9a;
$white: #ffffff;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin gradient-text {
  background: linear-gradient(to right, $primary-dark, $primary-medium);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

// Main Container Styles
.course-offerings {
  width: 100%;
  padding: 6rem 0rem 3rem 0;
  background: transparent;

  &__container {
    max-width: 80%;
    margin: 0 auto;
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__subtitle {
    text-align: center;
    color: $primary-medium;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
}

// Course Tabs Styles
.course-tabs {
  @include flex-center;
  gap: 2rem;
  margin-bottom: 3rem;

  &__button {
    position: relative;
    padding: 1rem 2rem;
    transition: all 0.3s;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    &.active {
      transform: scale(1.05);

      .course-tabs__text {
        color: $primary-dark;
      }

      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primary-dark;
      transform: scaleX(0);
      transition: transform 0.3s;
    }
  }

  &__text {
    font-size: 2rem;
    font-weight: 600;
    color: gray;
    transition: color 0.3s;
  }

  @media (max-width: 40em) {
    .course-tabs__text {
      font-size: 2rem;
    }
  }
}

// Course Content Layout
.course-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    grid-template-columns: 6fr 7fr;
  }
}

// Course Image Section
.course-image {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }

  &__location {
    @include flex-center;
    justify-content: flex-start;
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__college {
    color: rgba($white, 0.9);
  }
}

// Course Details Section
.course-details {
  background: $white;
  border-radius: 2rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

// College Info Styles
.college-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__logo {
    max-width: 150px;
    height: auto;
    margin-bottom: 1rem;
    object-fit: contain;
  }

  &__name {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #2c3e50;
  }
}

// Program Stats Styles
.program-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  &__item {
    @include flex-center;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 2rem;
    text-align: center;

    &--duration,
    &--months,
    &--intake {
      background: $vintagewash;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    color: $primary-dark;
  }

  &__label {
    font-size: 1.6rem;
    color: $primary-medium;
    margin-bottom: 0.25rem;
  }

  &__value {
    font-size: 1.6rem;
    font-weight: 600;
    color: $primary-dark;
  }
}

// Eligibility Section Styles
.eligibility {
  margin-bottom: 2rem;

  &__title {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $primary-dark;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  @media (max-width: 40em) {
    .eligibility__title {
      font-size: 2.3rem;
      text-align: center;
      font-weight: 700;
      margin: 3rem 0 2rem 0;
    }
  }
}

.eligibility-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, #f9fafb, $white);
  border: 1px solid #f3f4f6;
  border-radius: 1rem;

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    color: $primary-dark;
  }

  &__text {
    color: $primary-dark;
    font-weight: 500;
    font-size: 1.4rem;
  }

  @media (max-width: 40em) {
    .eligibility-item__text {
      font-size: 2rem;
    }
  }
}

// Program Features Styles
.program-features {
  margin-bottom: 2rem;

  &__title {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $primary-dark;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.feature-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, #f9fafb, $white);
  border: 1px solid #f3f4f6;
  border-radius: 1rem;

  &__dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.75rem;
    background: linear-gradient(to right, $primary-dark, $primary-medium);
  }

  &__text {
    color: $primary-dark;
    font-weight: 600;
    font-size: 1.4rem;
  }
}

// Course Actions Styles
.course-actions {
  display: flex;
  gap: 1.5rem;
  margin-top: 3rem;

  &__apply {
    flex: 1;
    padding: 1.2rem 2.4rem;
    background: $primary-dark;
    color: $white;
    border: none;
    border-radius: 1.2rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background: darken($primary-dark, 5%);
    }
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    padding: 1.2rem 2.4rem;
    background: $vintagewash;
    color: $primary-dark;
    border: none;
    border-radius: 1.2rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background: darken($vintagewash, 5%);
    }

    &-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  @media (max-width: 40em) {
    .course-actions__apply {
      font-size: 2.4em;
    }
    .course-actions__download {
      font-size: 2.4em;
    }
  }
}

// Responsive Styles
@media (max-width: 1024px) {
  .course-offerings {
    &__container {
      max-width: 90%;
    }
  }

  .course-tabs {
    flex-wrap: wrap;

    &__text {
      font-size: 1.6rem;
    }
  }

  .program-stats {
    grid-template-columns: repeat(2, 1fr);
  }

  .eligibility__grid,
  .program-features__grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .course-offerings {
    padding: 4rem 0rem 2rem 0;

    &__title {
      font-size: 3rem;
    }

    &__subtitle {
      font-size: 1.6rem;
    }

    &__container {
      max-width: 95%;
    }
  }

  .course-tabs {
    &__button {
      padding: 0.8rem 1.6rem;
    }

    &__text {
      font-size: 1.4rem;
    }
  }

  .course-image {
    &__title {
      font-size: 1.8rem;
    }
  }

  .program-stats {
    grid-template-columns: 1fr;

    &__label {
      font-size: 1.4rem;
    }

    &__value {
      font-size: 1.4rem;
    }
  }

  .course-actions {
    flex-direction: column;

    &__apply,
    &__download {
      width: 100%;
    }
  }

  .college-info {
    &__logo {
      max-width: 120px;
    }

    &__name {
      font-size: 1.6rem;
    }
  }
}
