//variables
//@import "mixins";

$dark: rgb(2, 27, 25);

$primary-dark: rgb(1, 51, 51);
$primary-dark2: rgb(1, 51, 51);
$primary-medium: rgb(28, 126, 116);

$primary-light: rgb(50, 203, 203);
$primary-superlight: #00444535;
$primary-yellow: #ffd800;

$dark: #043434;
$medium: #33cbcb;
$light: #1c7c7c;
$lighter: #d4f1f4;

$vintagewash: #f7efe5;
$blue: #015a9a;

$navbarheightinrem: 9rem;
$menubarheightinrem: 4rem;
$navbarheight: 7;
$menubarheight: 4;
$navbarcolor: $lighter;

//marker highlight
.realistic-marker-highlight {
  position: relative;
  margin: 0 2rem;
  color: $dark;
}
.realistic-marker-highlight:before {
  content: "";
  background-color: $medium;
  width: 105%;
  height: 0em;
  //position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  left: -0.5em;
  top: 0.1em;
  padding: 0 0.25em;
}

html {
  background-color: rgb(248, 248, 248);
}

.highlight-container,
.highlight {
  position: relative;
  z-index: 1;
  @media (min-width: 40em) {
    color: $dark;
  }
}

.highlight-container {
  display: inline-block;
}
.highlight-container:before {
  content: " ";
  //display: block;
  // height: 90%;
  // width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  //transform: rotate(2deg);
  // top: -1px;
  // left: -1px;
  border-radius: 20% 25% 20% 24%;
  //padding: 10px 3px 3px 10px;

  //content: "";
  background-color: $medium;

  width: 130%;
  height: 1.3em;
  //position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  left: -0.25em;
  top: 0em;

  padding: 1rem 0.25em;
  @media (max-width: 40em) {
    //background-color: $dark;
    //padding: 2rem;
    margin: 0 0.4rem;
  }
}

@mixin circle-animation($animation, $dur, $method, $times) {
  animation-name: $animation;
  animation-duration: $dur;
  animation-timing-function: $method;
  animation-iteration-count: $times;
  //animation: circle 10s linear infinite;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.highlighterpen-header {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  color: $dark;
  width: 80%;

  &::after {
    content: "";
    position: absolute;
    bottom: 2px; // Changed from -4px to -1px
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $medium;
  }
}

// @keyframes circle {
//   0% {
//     transform: rotate(0deg) translate (-165px) rotate (0deg);
//   }
//   100% {
//     transform: rotate(360deg) translate (-165px) rotate (-360deg);
//   }
// }

/// Navbar Section

.topsection {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 95%;
  margin: 0 auto;
  //background-color: $navbarcolor;
  //z-index: 2;
}

.navbar {
  display: flex;
  justify-content: space-between;
  //padding: 3rem 5rem;
  background-color: transparent;
  width: 100%;
  height: $navbarheightinrem;

  align-items: center;

  @media (max-width: 40em) {
    padding: 0 2em;
  }

  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;

  &__logo {
    //following width will decide the sixe of bigger logo
    width: 17rem;
    //padding: 1rem;
    display: none;
    margin: 0 0 0 3rem;

    &__img {
      width: inherit;
    }
  }

  &__smallLogo {
    width: 15rem;
    display: flex;
    justify-content: center;

    &__img2 {
      width: inherit;
    }
  }

  &__Mainheader {
    width: 40rem;
    font-size: 3rem;
    //font-weight: bold;
    font-weight: 700;
    text-align: center;

    &__link {
      text-decoration: none;
      color: black;
    }

    //This css is used for underline the text
    // text-decoration: underline;
    // text-decoration-style: solid;
    // font-weight: bold;
    // color: $primary-dark;
    // padding-bottom: 4px;
  }

  &__SecondaryButton {
    //width: 20rem;
    font-size: 1.6rem;
    display: none;
    background-color: $blue;
    padding: 2rem;
    border-radius: 2rem;
    color: white;
    font-weight: 500;
    cursor: pointer;

    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: darken($blue, 10%); /* Darker blue on hover */
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }
  }

  &__MenuIcon {
    display: block;
    font-size: 3rem;
    color: $primary-dark;
  }

  @media (min-width: 40em) {
    &__logo {
      display: flex;
    }
    &__smallLogo {
      display: none;
    }
    &__SecondaryButton {
      display: flex;
    }
    &__MenuIcon {
      display: none;
    }
  }
}

.menubar {
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  //background-color: $medium;
  width: 40%;
  height: $menubarheightinrem;
  //margin: auto;
  align-items: center;
  //follwing code like was to make a underline for menu bar
  //border-bottom: 5px solid $primary-dark;
  padding: 0 0 0 0;
  display: none;
  cursor: pointer;

  &__SecondaryButton {
    font-size: 1.9rem;

    &__link {
      text-decoration: none;
      color: black;
    }

    //following commented part is for making boxes around menu items
    //background-color: #00444535;
    //padding: 1.8% 4%;
  }

  &__SecondaryButton:hover {
    font-weight: 500;
  }
}

.drawer {
  position: absolute;
  right: 0;
  background-color: white;
  width: 40em;
  height: 45em;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  z-index: 1000;

  &__row {
    width: 100%;
    font-size: 2rem;
    padding: 6%;
    border-bottom: 0.5px solid $primary-dark2;
    cursor: pointer;
    font-weight: 500;
  }

  &__row:hover {
    background-color: $primary-light;
  }
}

@media (min-width: 40em) {
  .menubar {
    display: flex;
  }
  .drawer {
    display: none;
  }
}

/// Hero Section
///
.heading-container {
  line-height: 1.3;
  text-align: center;
  //padding: 44px;
  display: flex;
  border-radius: 1rem;

  //background-color: rgb(218, 214, 214);
  //background-color: $dark;
  //background-image: linear-gradient(rgb(15, 20, 15), white);
}

.hero {
  //background-color: transparent;
  //height: 70vh;
  //margin-top: (20 - ($menubarheight + $navbarheight) rem);
  //margin-top: 20rem - ($menubarheightinrem + $navbarheightinrem);
  @media (max-width: 40em) {
    margin-top: 10rem;
    margin-top: 17rem - ($menubarheightinrem + $navbarheightinrem);
  }

  //Making content appear in center
  display: flex;
  justify-content: center;
  align-items: center;

  &__ATFbanner {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //width: 90%;
    //color: $primary-light;
    //transform: translate(-50%, -50%);
    @media (max-width: 40em) {
      transform: translate(-50%, -60%);
    }

    //uncomment this in order to create the box
    //border: 1px solid $primary-light;
    //padding: 10px;

    &__SDGicon {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      &__SDGicons {
        width: 10rem;
        margin: 2rem;
        border-radius: 1rem;
        @media (max-width: 40em) {
          width: 7rem;
          margin: 1rem;
          border-radius: 1rem;
        }
      }
      @media (max-width: 40em) {
        width: 80%;
        //margin: 10rem auto;
        margin: 2rem auto 0 auto;
        // flex-direction: column;
        flex-wrap: wrap;
      }
    }

    &__text {
      width: 100%;
      font-size: 3.5rem;
      text-align: center;
      font-weight: 700;
      margin-bottom: 7rem;
      margin-top: 5rem;
      word-spacing: 0rem;
      color: white;
      @media (max-width: 40em) {
        font-size: 3rem;
        width: 90%;
        letter-spacing: 0.3rem;
        text-align: center;
        margin: 5rem auto;
      }
    }
    &__text1 {
      width: 60%;
      font-size: 2rem;
      text-align: center;
      font-weight: 700;
      margin: 7rem auto 2rem auto;
      word-spacing: 0rem;
      color: $dark;
      //color: white;
      @media (max-width: 40em) {
        font-size: 3rem;
        //letter-spacing: 0.5rem;
        width: 80%;
        text-align: center;
        margin: 8rem auto 3rem auto;
      }

      border: 1px solid black;
      padding: 2rem;
      border-radius: 2rem;
    }
    &__text2 {
      width: 70%;
      font-size: 2.5rem;
      text-align: center;
      font-weight: 700;
      margin: 0 auto;
      color: $medium;
    }
    &__text3 {
      font-size: 1.5rem;
      color: white;
      text-align: center;
      //font-weight: 500;
      //margin-top: 2%;
    }
  }
}

// .bgvideo {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   //z-index: -10;
//   background-color: #043434;
//   &__content {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//     object-position: top;
//     filter: brightness(25%);
//     z-index: -1;
//     //border: 1px solid white;
//   }
// }

video {
  filter: brightness(30%);
}

// img {
//   filter: brightness(50%);
// }

//NoticeBoard

.noticeboard {
  width: 80%;
  //height: 40em;
  margin: 0 auto;
  //background-color: gray;
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
  @media (max-width: 40em) {
    flex-direction: column;
  }

  &__projecthighlights {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 40em) {
      width: 100%;
      flex-direction: column;
    }

    &_header {
      font-size: 2rem;
      font-weight: 700;
      margin: 1.5em 0em;
    }
  }
}

/// blogsection

.blogsection {
  //width: 100%;
  //background-color: gainsboro;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 14em;
  overflow-x: hidden;
  // white-space: nowrap;
  padding: 3rem;
  // width: 100%;
  // border: 1px solid $dark;
  // border-radius: 2rem;

  &__background {
    //background-color: rgb(17, 64, 59);
    // padding: 0.001rem 0;
  }

  &__heading {
    font-size: 3rem;
    font-weight: 700;
    //color: white;
    color: $dark;
    margin: 3rem auto 4rem auto;
  }

  &__blogs {
    //background-color: #ffd800;
    display: flex;
    justify-content: left;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    //width: 200%;
    //margin: 0 0rem;
    //height: 45em;
  }
}

@media (max-width: 40em) {
  .blogsection {
    overflow-x: scroll;
    margin: 3em 3em;
    //width: 100%;
  }
}

//fourth Card
.contactsection {
  //background-color: $primary-dark;
  width: 90%;
  //height: 70vh;
  //margin: 3rem;
  //background-color: red;
  border-radius: 1rem;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  //transform: translate(3%);
  margin: 4rem auto;

  @media (max-width: 40rem) {
    flex-direction: column;
    //height: 100%;
    //padding-bottom: 7%;
    //height: 70vh;
  }

  &__section {
    width: 50%;
  }

  &__bgcolor {
    //background-color: #32cbcb;
  }

  &__contactinfo {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 40em) {
      //display: none;
      width: 100%;
      //height: 80%;
    }

    &__contactTitle {
      font-size: 4rem;
      width: 70%;
      text-align: center;
      //font-style: oblique;
      font-weight: 600;
      color: $light;
      @media (max-width: 40em) {
        //display: none;
        font-size: 3.5rem;
        font-style: normal;
        // width: 100%;
        // text-align: center;
        font-weight: 700;
        color: $light;
        margin: 4rem auto 0rem auto;
      }
    }

    &__header {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      @media (max-width: 40em) {
        display: none;
      }
      &__logo {
        width: 5rem;
        @media (max-width: 40em) {
          display: none;
        }
      }
      &__headline {
        font-weight: 700;
        font-size: 2rem;
        padding-left: 1%;
        @media (max-width: 40em) {
          display: none;
        }
      }
    }

    &__subtitle {
      font-size: 1.5rem;
      @media (max-width: 40em) {
        display: none;
      }
    }

    &-info {
      width: 80%;
      height: 80%;
      color: white;
      //padding: 10px 50px;
      font-family: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-hello {
        font-size: 3.6rem;
        padding: 1rem 0px;
      }
      &-tagline {
        font-size: 2rem;
        padding: 1rem 0;
      }
      &-discription {
        font-size: 1.5rem;
        color: #c0f0f0b6;
      }
      &-contact {
        display: flex;
        align-items: center;
        font-family: inherit;
        font-size: 1.5rem;
        margin: 1.3rem 0;
      }
      &-callnemail {
        color: white;
        text-decoration: none;
      }
      &-icon {
        font-size: 2rem;
        margin-right: 1rem;
      }
    }
  }

  &__contactform {
    height: 100%;
    //width: 50%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 40em) {
      width: 100%;
      //height: 100%;
    }

    &-form {
      height: auto;
      width: 100%;
      background-color: white;
      border-radius: 1rem;
      padding: 3rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      border: 2px $primary-dark solid;
      // --tw-shadow: 10px 10px 15px 10px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
      // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      //------ form -------//
      &-allinput {
        //background-color: red;
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 500;
        //width: 100%;
        //height: 100%;
        //height: 10px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        //height: 0%;

        &-input {
          //margin-bottom: 17px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin: 0.8rem 0;

          &-inputfield {
            font-size: 1.5rem;
            //background-color: red;
            font-family: inherit;
            color: $light;
            font-weight: 500;
            outline: none;
            margin: 1rem 0 1rem 0;
            border: none;
            border-bottom: 1px solid $primary-superlight;
            &:hover {
              border-bottom: 1px solid $primary-dark2;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      &-button {
        //margin-top: 2rem;
        //margin-left: auto;
        margin: 2rem 0 0 auto;
        width: 50%;
        cursor: pointer;
        padding: 1rem;
        font-size: 1.8rem;
        //border: 1px solid $primary-dark2;
        border-radius: 1rem;
        background-color: $primary-dark2;
        color: white;
        font-family: inherit;
        font-weight: 500;
        --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        @media (max-width: 40em) {
          width: 100%;
          //height: 100%;
        }

        // &:hover {
        //   transform: translateY(-5px);
        //   //color: black;
        //   transition: all 0.1s;
        //   //background-color: $primary-light;
        //   //color: $primary-dark;
        //   --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        //     0 10px 10px -5px rgba(0, 0, 0, 0.04);
        //   box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        //     var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        // }
        // &:active {
        //   transform: translateZ(5px);
        //   --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        //     0 4px 6px -2px rgba(0, 0, 0, 0.05);
        //   box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        //     var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        // }
      }
    }
  }
}

/// Coreofferings section
.coreofferings {
  background-color: $primary-dark;
  height: auto;
  //margin: 3em 0 0 0;
  padding: 4rem 0;

  background-color: $vintagewash; /* Light grey background */
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each grid cell */

  //Making contect appear in center
  display: flex;
  justify-content: center;
  align-items: center;

  &__section {
    width: 90%;
    color: white;
  }

  &__title {
    font-size: 3rem;
    text-align: center;
    //border-bottom: 2px solid $primary-light;
    padding-bottom: 1rem;
    //width: 20%;
    font-weight: 500;
    margin: 0 auto;
    font-weight: 600;
    @media (max-width: 40em) {
      width: 50%;
    }
  }

  &__whatwedo {
    display: flex;
    margin-top: 3%;
    font-size: 2rem;
    gap: 4%;
    text-align: center;
    @media (max-width: 40em) {
      flex-direction: column;
    }

    &__title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &__subtext {
      font-size: 2rem;
      @media (max-width: 40em) {
        font-weight: 400;
      }
    }

    &__para {
      width: 100%;
      line-height: 2.8rem;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 300;
      border: 1px solid white;
      border-radius: 10px;
      padding: 1em;
      color: white;
      //margin-bottom: 4%;

      @media (max-width: 40em) {
        margin-bottom: 4%;
      }
    }
  }

  &__offerings {
    width: 70%;
    //border: 1px solid black;

    &__text {
      font-size: 2rem;
      text-align: center;
      font-weight: 500;
      border: 1px solid white;
      border-radius: 10px;
      padding: 1em;
      color: white;
    }
  }
}

// footer

.footer {
  padding: 1em 0 4em 0;
  //background-color: $primary-superlight;
  background-color: $primary-dark;
  margin-top: 3em;
  color: white;

  @media (max-width: 40em) {
    height: 40vh;
  }

  &__logo {
    width: 5em;
  }

  &__links {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
    align-items: center;
    font-size: 1rem;

    @media (max-width: 40em) {
      flex-direction: column;
      height: 100%;
    }

    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      //cursor: pointer;
      margin-bottom: 2%;
      //font-size: 1.5rem;
      width: 40em;

      &__logo {
        font-size: 4rem;
        font-weight: 600;
        color: $primary-dark2;
        //color: white;
      }

      &__copyright {
        font-size: 1.5rem;
      }

      &__heading {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1rem;
        //color: $primary-dark2;
        color: white;
        @media (max-width: 40em) {
          font-size: 2.5rem;
        }
      }

      &__items {
        font-size: 1.5rem;
        //cursor: pointer;

        @media (max-width: 40em) {
          font-size: 2rem;
        }

        &-nolink {
          font-size: 1.5rem;
        }
        &-link {
          font-size: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;

    &-icon {
      font-size: 3rem;
      margin: 0 1rem;
      color: $primary-dark2;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

// Utilities
.blogcard {
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;

  margin: 1em 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  flex-grow: 0;
  flex-shrink: 0;
  height: 80%;
  width: 25em;
  height: 35em;

  overflow: hidden;
  cursor: pointer;

  &__img {
    height: 50%;

    &__cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__info {
    height: 50%;
    //background-color: #ffd800;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 2em;
    &__blogheading {
      font-weight: 500;
      font-size: 2rem;
    }
    &__blogcredits {
      font-size: 1.3rem;
    }
  }
}
.blogcard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.projectcard {
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  //flex-grow: 0;
  //flex-shrink: 0;
  height: 100%;
  //width: 100%;
  //height: 35em;

  overflow: hidden;
  cursor: pointer;

  @media (max-width: 80em) {
    //width: 45%;
  }
  @media (max-width: 40em) {
    width: 90%;
  }

  &__img {
    height: 40%;

    &__cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__info {
    height: 60%;
    //background-color: #ffd800;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 2em;
    &__domain {
      margin-top: 2rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: $light;
    }
    &__blogheading {
      font-weight: 700;
      font-size: 1.6rem;
      margin: 1rem 0;
    }
    &__blogcredits {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
    &__collaboration {
      font-size: 1.5rem;
      font-weight: 600;
      color: $light;
      margin-bottom: 1rem;
    }
  }
}
.projectcard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.contactcard {
  width: 90%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0 0 1em 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  //cursor: pointer;
  overflow: hidden;
  @media (max-width: 40em) {
    flex-direction: row;
    //width: 90%;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    // flex-grow: 0;
    // flex-shrink: 0;
    // overflow: hidden;
    @media (max-width: 40em) {
      //flex-direction: row;
      //width: 100%;
      justify-content: space-between;
    }
  }

  &__imagesection {
    display: flex;
    //width: 50%;
    flex-direction: row;
    overflow: hidden;
    @media (max-width: 40em) {
      //flex-direction: row;
      width: 100%;
    }

    &__img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    margin: 6%;
  }

  &__subtext {
    font-size: 1rem;
    margin: 0% 6% 6% 6%;
    font-weight: 500;
  }

  &__img {
    //height: 10%;
    width: 10%;
    //margin: 2%;
  }
}
.contactcard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.highlightcard {
  //background-color: aqua;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  //cursor: pointer;
  width: 80%;
  //height: 25rem;
  margin-bottom: 1rem;
  @media (max-width: 40em) {
    flex-direction: row;
    width: 90%;
    height: auto;
    //justify-content: space-evenly;
  }

  &__img {
    height: 40%;
    @media (max-width: 40em) {
      height: auto;
      //width: 50%;
      width: 90%;
    }

    &__cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__info {
    //height: 50%;
    //background-color: #ffd800;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1rem 1rem;
    @media (min-width: 40em) {
      height: 50%;
    }
    @media (max-width: 40em) {
      width: 80%;
    }

    &__blogheading {
      font-weight: 500;
      font-size: 2rem;
    }
    &__blogcredits {
      font-size: 1.3rem;
    }
  }
}
.highlightcard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

// center of excellence

.centerofexcellencecard {
  width: 50%;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  margin: 4% auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  justify-content: center;
  @media (max-width: 40em) {
    width: 80%;
    text-align: center;
    margin-top: 5%;
  }

  &__section {
    //display: flex;
    //flex-direction: column;
    width: 80%;
    // flex-grow: 0;
    // flex-shrink: 0;
    // overflow: hidden;
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    margin: 6%;
  }

  &__subtext {
    font-size: 1rem;
    margin: 0% 6% 6% 6%;
    font-weight: 500;
  }

  &__img {
    height: 10%;
    width: 10%;
    margin: 2%;
  }
}
.centerofexcellencecard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

///Blogs Page

.blogs {
  margin-top: 20rem - ($menubarheightinrem + $navbarheightinrem);
  @media (max-width: 40em) {
    margin-top: 10rem;
  }

  &__blogsectiontitle {
    font-size: 2.7rem;
    text-align: center;
    margin: 5rem 0;
  }

  &__header {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    padding: 2% 0;
  }

  &__blog {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    justify-content: space-evenly;
    //background-color: #ffd800;
    margin: 0 auto;
  }
  &__blogsarea {
    width: 30%;
    //background-color: #1c7c7c;
    display: flex;
    flex-direction: row;
    margin: 1rem;
    justify-content: center;
    @media (max-width: 40em) {
      width: 100%;
      //background-color: #ffd800;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

//Subscribe container

.subscribe_container {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10em;
  @media (max-width: 40em) {
    width: 90%;
  }
}

//Blogs component
.header1 {
}

.blogpage {
  width: 80%;
  //background-color: #ffd800;
  //height: 40em;
  margin: 19em auto;
}

.titletext {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}

.header3text {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0.7%;

  &__header {
    font-size: 2rem;
    display: inline;
    font-weight: 600;
  }
}

.header2text {
  font-size: 3rem;
  font-weight: 500;
  margin: 4% 0 2% 0;
}

.blogimage_container {
  margin-top: 3%;
  display: flex;
  justify-content: center;
}

.blogimage {
  position: relative;
  width: 50%;
  height: 50%;
  z-index: -10;
  object-fit: cover;
  object-position: top;
  margin-right: auto;
}

.blogparagraph {
  font-size: 2rem;
  margin: 3% 0;
  line-height: 3rem;
}

.bloglist1 {
  margin: 1%;
  font-size: 2rem;
  line-height: 3rem;
}

.bloglist2 {
  margin: 1%;
  font-size: 2rem;
  line-height: 2.5rem;
}

.bloglist2listtitle {
  font-weight: 700;
  font-size: 1.7rem;
}

.bloglist2listtext {
  margin-bottom: 1%;
  font-size: 1.7rem;
}

//center of excellence

.centerofexcellence {
  width: 80%;
  //background-color: #ffd800;
  //height: 40em;
  margin: 19em auto 0 auto;

  &__ATFCE {
    font-size: 2.9rem;
    text-align: center;
    border-bottom: 2px solid $primary-light;
    padding-bottom: 4px;
    width: 50%;
    margin: 0 auto;
    @media (max-width: 40em) {
      width: 50%;
    }
  }
}

//About page

.aboutpage {
  //margin: 0 auto 0 auto;
  //width: 80%;
  //margin-top: 17rem - ($menubarheightinrem + $navbarheightinrem);
  @media (max-width: 40em) {
    //margin-top: 17rem - ($menubarheightinrem + $navbarheightinrem);
  }

  &__missionvisionsection {
    width: 100%;
    background-color: #043434;
    padding: 6rem 0;
    @media (max-width: 40em) {
      //padding-top: 1rem;
    }
  }

  &__pagetitle {
    //padding-top: 6rem;
    font-size: 3rem;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: white;
  }

  &__section {
    display: flex;
    gap: 3rem;
    width: 80%;
    margin: 0 auto;
    padding: 3rem 0;
    @media (max-width: 40em) {
      flex-direction: column;
      width: 90%;
      gap: 0rem;
    }
  }

  &__mission {
    margin: 1% 0;
    font-size: 1.5rem;
    //text-align: center;
    //font-weight: 500;
    //border: 1px solid black;
    border-radius: 10px;
    padding: 2em;
    //color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    //background-color: rgb(79, 190, 190);
    background-color: $light;
    @media (max-width: 40em) {
    }

    &__title {
      font-size: 2rem;
      text-align: left;
      font-weight: 700;
      color: $lighter;
    }
    &__paragraph {
      font-size: 1.8rem;
      line-height: 2.5rem;
      text-align: left;
      margin: 2% 0;
      color: $lighter;

      &_underline {
        // display: inline;
        // text-decoration: underline;
        // text-decoration-skip-ink: none;
        // padding-bottom: 4px;
        //font-weight: 500;
        display: inline;
        border-bottom: 2px solid $primary-light;
        padding-bottom: 2px;
      }
    }
  }

  &__whatwedosection {
    // background-color: $primary-superlight;
    padding: 0 6%;
    width: 80%;
    margin: 1rem auto;
    color: black;
    border-radius: 2rem;
    border: 1px solid black;
    @media (max-width: 40em) {
      margin: 5rem auto;
    }

    &_title {
      white-space: pre-wrap;
      font-size: 3rem;
      text-align: left;
      //border-bottom: 2px solid $primary-light;
      padding-bottom: 4px;
      width: 100%;
      margin: 2rem auto;
      font-weight: 500;
      @media (max-width: 40em) {
        width: 100%;
        font-size: 4rem;
      }

      &_subtitle {
        color: $light;
        font-weight: 700;
      }
    }

    &__title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &__para {
      line-height: 2.8rem;
      @media (max-width: 40em) {
        margin-bottom: 4%;
      }
      //margin-bottom: 4%;
      font-size: 1.5rem;
      text-align: left;
      font-weight: 500;
      //border: 1px solid $primary-dark;
      //border-radius: 10px;
      //padding: 1em;
      color: black;
    }

    &__whatwedo {
      display: flex;
      margin-top: 3%;
      font-size: 2rem;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 1%;
      text-align: center;

      //background-color: red;

      @media (max-width: 40em) {
        flex-direction: column;
      }

      &__title {
        // background-color: red;
        font-size: 2.3rem;
        font-weight: 600;
        margin-bottom: 1rem;
        @media (max-width: 40em) {
          font-size: 2.8rem;
        }
      }

      &__para {
        //background-color: red;
        line-height: 2.8rem;
        font-size: 1.5rem;
        text-align: center;
        //font-weight: 500;
        //border: 1px solid white;
        border-radius: 10px;
        color: black;
        width: 30%;
        //overflow: hidden;
        //cursor: pointer;
        margin-bottom: 3%;

        text-align: left;
        font-weight: 500;
        //border: 1px solid $primary-dark;
        //border-radius: 10px;
        //padding: 1em;
        color: black;
        @media (max-width: 40em) {
          margin-bottom: 4%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__image {
        padding: 1rem 0;

        &_img {
          width: 20%;
          @media (max-width: 40em) {
            width: 15%;
          }
        }

        &_value {
          background-color: $medium;
          height: 5rem;
          width: 5rem;
          border-radius: 50%;
          display: flex;
          color: black;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          //display: inline-block;
        }
      }

      &__title2 {
        font-size: 1.7rem;
        @media (max-width: 40em) {
          font-size: 2.3rem;
        }
      }
    }
  }

  &__pov {
    width: 80%;
    padding: 3%;
    margin: 0 auto;
    border: 1px solid black;
    border-radius: 10px;
    background-color: $dark;
    color: white;
    @media (max-width: 40em) {
      text-align: center;
    }

    &__title {
      font-size: 1.8rem;
      color: $primary-light;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    &__paragraph {
      font-size: 2rem;
      color: white;
      font-weight: 500;
      line-height: 3rem;
    }
  }
}

//pop
.pop {
  margin: 17rem auto 0 auto;
  // width: 80%;

  &__poparea {
    width: 100%;
    background-color: #043434;

    &__popsection {
      display: flex;
      margin: 0 auto;
      //background-color: $light;
      width: 80%;
      padding: 3rem;
      border-radius: 2rem;
      flex-direction: column;
      @media (max-width: 40em) {
        flex-direction: column;
      }

      &__section1 {
        //background-color: red;
        //width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        @media (max-width: 40em) {
          text-align: center;
          width: 100%;
          margin: 2rem 0;
        }
        &__title {
          font-size: 4rem;
          font-weight: 600;
          color: white;
          margin: 3rem 0 2rem 0;
          @media (max-width: 40em) {
            margin: 2rem 0;
          }
        }
        &__text {
          width: 70%;
          margin: 0 auto;
          color: $lighter;
          text-align: left;
          line-height: 3rem;
          font-size: 1.8rem;
          margin-bottom: 5rem;
          text-align: center;
          //flex-grow: 1;

          @media (max-width: 40em) {
            text-align: center;
            width: 100%;
          }
        }
      }
      &__section2 {
        //width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        //background-color: #ffd800;
        margin: 0 auto;
        @media (max-width: 40em) {
          margin: 2rem 0;
        }
      }
    }
  }

  // &__popcardsection {
  //   display: flex;

  //   justify-content: space-evenly;
  // }
}

.popcard {
  background-color: white;
  border: 1px solid white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 1em 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  //height: 80%;
  //width: 25em;
  width: 25rem;
  height: 38rem;

  &__img {
    height: 100%;

    &__cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__info {
    height: 40%;
    //background-color: #ffd800;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem 2em;
    &__blogheading {
      font-weight: 500;
      font-size: 1.7rem;
    }
    &__blogcredits {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
}

.popcard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

//Pop profile page

.popprofile {
  margin: 17rem auto;
  width: 80%;

  &__profilecard {
    border-radius: 2rem;
    //display: flex;
    margin: 0 0 1em 0;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    //background-color: red;
    background-color: white;
    padding: 3%;

    &__project {
      width: 100%;
      display: flex;
      gap: 2rem;
      //background-color: #043434;
      @media (max-width: 40em) {
        width: 100%;
        margin: 5rem 0;
        font-size: 2.5rem;
      }
    }

    &__profileheader {
      display: flex;
      //flex-direction: row;
      //background-color: #ffd800;
      //height: 100%;
      //height: 20em;
      width: 90%;
      margin: 2rem auto;
      @media (max-width: 40em) {
        flex-direction: column;
        //height: 25em;
      }

      &__profilephoto {
        //background-color: #ffd800;
        width: 30%;
        display: flex;
        justify-content: space-around;
        @media (max-width: 40em) {
          width: 50%;
        }
        &_photo {
          height: 90%;
          width: 60%;
          //width: 100%;
          object-fit: cover;
          border-radius: 100%;
          @media (max-width: 40em) {
            margin: 4rem 0;
          }
        }
      }
    }
    &__profilename {
      width: 70%;
      padding-left: 4%;
      //background-color: aqua;
      @media (max-width: 40em) {
        width: 100%;
        padding-left: 0%;
      }

      &_tag {
        font-weight: 500;
        font-size: 1.7rem;
        margin-top: 1%;
      }
    }

    &__aboutsection {
      width: 90%;
      margin: 0 auto;
    }
  }
}

//Join as a pop section

.popsection {
  &__joinpop {
    display: flex;
    //flex-direction: column;
    margin: 5rem auto;
    width: 80%;
    gap: 5rem;
    @media (max-width: 40em) {
      flex-direction: column;
    }

    &__leftsection {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      justify-content: center;

      &__title {
      }
      &__subtitle {
        font-size: 2rem;
        font-weight: 600;
        //text-align: center;
        margin-top: 2rem;
      }
      &__para {
        font-size: 1.8rem;
        //font-weight: 600;
        // text-align: center;
        margin-top: 1.5rem;
      }
    }

    &__rightsection {
      width: 100%;
    }
  }
}

//cosmos profile

.cosmoshub {
  margin: 15rem auto 0 auto;
  width: 80%;

  //margin: 9rem auto 0 auto;
  //padding: 6rem 0;
  // margin: 0 auto 0 auto;
  margin-top: 22rem - ($menubarheightinrem + $navbarheightinrem);
  width: 100%;
  padding: 5rem 0;
  //border: 1px solid black;
  //border-radius: 10px;
  //background-color: $light;
  background-color: #043434;
  @media (max-width: 40em) {
    margin: 10rem auto 0 auto;
    margin-top: 18rem - ($menubarheightinrem + $navbarheightinrem);
  }

  &__herotitle {
    font-size: 3rem;
    color: white;
    font-weight: 600;
    text-align: center;
    &_subtitle {
      font-weight: 500;
      font-size: 2rem;
      color: white;
      margin-top: 2rem;
      text-align: center;
      letter-spacing: 0.15rem;
    }
  }

  &__content {
    display: flex;
    width: 80%;
    margin: 5rem auto 0 auto;
    gap: 2rem;
    flex-direction: column;
    //border-radius: 2rem;
    //border: black solid 1px;
    //padding-left: 3rem;
    //Inline Css applied please check
    border-radius: 2rem;
    //border: "black solid 1px",
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0.1, 0.1);

    &__section {
      display: flex;
      width: 100%;
      gap: 5rem;
      justify-content: space-between;
      @media (max-width: 40em) {
        flex-direction: column;
      }

      &__textsection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;

        @media (max-width: 40em) {
          width: 100%;
        }

        &__title {
          font-size: 3rem;
          font-weight: 500;
          color: white;
          @media (max-width: 40em) {
            text-align: center;
            margin-bottom: 2rem;
          }
        }

        &__text {
          //
          //margin: 0 auto;
          color: $lighter;
          //color: black;
          //text-align: center;
          line-height: 2.5rem;
          font-size: 2rem;
          //flex-grow: 1;
          //margin: 3rem;

          @media (max-width: 40em) {
            text-align: center;
          }
        }

        &__text1 {
          //
          //margin: 0 auto;
          //color: $lighter;
          color: black;
          text-align: center;
          line-height: 2.5rem;
          font-size: 2rem;
          //flex-grow: 1;
          margin: 3rem;

          @media (max-width: 40em) {
            text-align: center;
          }
        }
      }

      &__imgsection {
        display: flex;
        justify-content: center;
        width: 50%;
        @media (max-width: 40em) {
          width: 100%;
        }
        &_img {
          width: 100%;
        }
      }
    }

    &__icons {
      //width: 50%;
      //background-color: #33cbcb;
    }
  }

  &__section {
    width: 70%;
    margin: 0 auto;

    @media (max-width: 40em) {
      width: 100%;
    }

    &__ruleicons {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      //border-radius: 0 0 0.5rem 0.5rem;
      border-radius: 0.5rem;
      height: auto;
      width: 80%;
      margin: 1rem auto;
      padding: 2rem;
      font-size: 2rem;
      display: flex;
      align-items: center;
      padding-left: 6rem;
      font-weight: 700;
      background-color: $primary-light;

      &__img {
        //height: 10%;
        width: 5rem;
        border-radius: 0.7rem;
        margin-right: 2%;
      }
    }

    &__content {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      //border-radius: 0 0 0.5rem 0.5rem;
      border-radius: 0.5rem;
      height: auto;
      width: 80%;
      margin: 1rem auto;
      padding: 2rem;
      font-size: 2rem;
      display: flex;
      align-items: center;
      padding-left: 6rem;
      background-color: $primary-light;
    }

    &__card {
      //text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      //box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
      cursor: pointer;
      //background-color: red;
      border-radius: 0.5rem;
      height: auto;
      padding: 1.8rem;
      width: 80%;
      margin: 0 auto;
      display: flex;
      //justify-content: space-evenly;
      align-items: center;

      &_arrow {
        margin-left: 2rem;
        font-size: 2rem;
      }
      &_title {
        font-size: 2.3rem;
        margin-left: 2rem;
        font-weight: 500;
      }
    }
  }
}

//objectives tab

.objectives {
  //margin: 9rem auto 0 auto;
  //padding: 6rem 0;
  margin: 0 auto 0 auto;
  //margin-top: 22rem - ($menubarheightinrem + $navbarheightinrem);
  width: 100%;
  padding: 5rem 0;
  //border: 1px solid black;
  //border-radius: 10px;
  //background-color: $light;
  background-color: #043434;

  @media (max-width: 40em) {
    margin: 10rem auto 0 auto;
    margin-top: 18rem - ($menubarheightinrem + $navbarheightinrem);
  }

  &__title {
    color: white;
    //font-weight: 700;
    font-size: 3rem;
    text-align: center;

    &__subtitle {
      font-size: 2rem;
      font-weight: 500;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    width: 80%;
    margin: 0 auto;
    gap: 2rem;
    &__text {
      //width: 50%;
      margin: 2rem auto;
      color: $lighter;
      text-align: center;
      line-height: 3.2rem;
      font-size: 2rem;
    }
    &__icons {
      //width: 50%;
      //background-color: #33cbcb;
    }
  }

  &__goals {
    width: 80%;
    margin: 2rem auto;
    //margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    @media (max-width: 40em) {
      flex-direction: column;
      //gap: 0rem;
      width: 100%;
    }
  }

  //background-color: $primary-dark;
}

.detailedcards {
  background-color: white;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 3em 0 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  //cursor: pointer;
  width: 49%;
  padding: 2rem;

  margin: 1em auto;

  @media (max-width: 40em) {
    width: 80%;
    margin: 1rem auto;
  }
  //height: fit-content;
  //flex-basis: 1;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $dark;
    //padding: 2rem;
  }
  &__text {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: $dark;
  }

  &__goal {
    display: flex;
    //align-items: center;
    gap: 1rem;
    color: $dark;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    &__textsection {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__label {
      background-color: $medium;
      border-radius: 1rem;
      width: fit-content;
      padding: 0.5rem 1rem;
      color: black;
      font-weight: 500;
      font-size: 1.3rem;
      margin: 1rem 0 2rem 0;
      font-size: "1.5rem";
    }
    &__img {
      width: 5rem;
      border-radius: 0.7rem;
      margin: 2% 0;
    }
  }
}

.detailedcards:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.SGC {
  width: 80%;
  margin: 5% auto;
  color: $dark;
  //justify-content: space-between;

  //justify-content: center; /* Aligns content horizontally */
  //align-items: center; /* Aligns content vertically */
  //height: 50vh;

  display: flex;
  justify-content: space-around;
  align-items: center;

  // background-color: $dark;
  // padding: 3rem;
  // border-radius: 2rem;

  @media (max-width: 40em) {
    flex-direction: column;
  }

  &__homepage {
    border: solid 1px;
    border-radius: 2rem;
    padding: 2rem;
  }

  &_1 {
    width: 50%;
    @media (max-width: 40em) {
      width: 100%;
      margin: 3% 0;
      text-align: center;
    }
  }

  &_2 {
    width: 50%;
    display: flex;
    justify-content: center;
    @media (max-width: 40em) {
      width: 100%;
      text-align: center;
    }

    &_img {
      width: 80%;
      //height: 1rem;
    }
    // width: 1rem;
    // height: 1rem;
    //width: auto;
    //max-width: 100px; /* Ensures the image doesn't exceed the container */
    //max-height: 100px;
    //position: relative;
    //top: 50%;
    //left: 50%;
    //width: 10%;
    //color: $primary-light;
    //transform: translate(-50%, -50%);
  }

  &__title {
    font-size: 3rem;
    text-align: left;
    //font-weight: 700;
    //color: $medium;
    color: $light;
    font-weight: 700;
    margin-bottom: 2rem;
    @media (max-width: 40em) {
      text-align: center;
    }
  }

  &__colorliner {
    background-color: $light;
    height: 1rem;
  }
}

//collaboration

.collaboration {
  width: 80%;
  //height: 40em;
  //margin: 0 auto;
  //margin-top: 4%;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;

  //width: 80%;
  /* height: 40em; */
  /* margin: 0 auto; */
  margin: 5rem auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //position: relative;
  /* z-index: -10; */
  /* gap: 4rem; */
  @media (max-width: 40em) {
    flex-direction: column;
    align-items: center;
    margin: 4rem auto;
  }

  &__imgsection {
    border: 1px solid black;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;

    &_industry {
      width: 3rem;
      height: 3rem;
      background-color: $primary-dark;
      border-radius: 50%;
      overflow: hidden;

      @include keyframes(circle) {
        0% {
          transform: rotate(0deg) translate (-165px) rotate (0deg);
        }
        100% {
          transform: rotate(360deg) translate (-165px) rotate (-360deg);
        }
      }

      @include circle-animation(circle, 10s, linear, infinite);

      //@include circle-animation;
      //animation: circle 10s linear infinite;
    }
    &_researchers {
    }
    &_institutions {
    }
  }

  &__contentsection {
    // font-size: 3rem;
    // font-weight: 500;
    width: 50%;
    @media (max-width: 40em) {
      width: 100%;
      text-align: center;
    }

    &__head {
      margin: 2rem 0 4rem 0;
      &_header {
        font-size: 4.2rem;
        font-weight: 700;
        color: $light;
      }
      &_title {
        font-size: 2rem;
        font-weight: 400;
      }
    }

    &_subtitle {
      //background-color: red;
      font-size: 2.5rem;
      font-weight: 500;
      &_partner {
        //background-color: rebeccapurple;
        margin-bottom: 2rem;
        background-color: rgb(240, 237, 237);
        padding: 1.5rem;
        border-radius: 1rem;
        transition-duration: 500ms;

        &_bottom {
          font-size: 2rem;
          margin-left: 7rem;
          letter-spacing: 0.06rem;
          font-weight: 400;
          text-align: left;
          display: none;
          //transition: display 2s ease-in-out;
          //opacity: 0;
          //transition-timing-function: linear;
          //height: 0;
          transition: height 2s;

          &_points::before {
            content: "-";
            font-size: 3rem;
            font-weight: 500;
            margin-right: 0.6rem;
            text-align: left;
          }
        }
        &_top {
          //background-color: #1c7c7c;
          font-weight: 700;
          color: #043434;
          font-size: 3rem;
          display: flex;
          align-items: center;

          &_img {
            width: 5rem;
            margin-right: 2rem;
          }
        }

        &_top:hover ~ &_bottom {
          display: block;
          //height: 10rem;
          //opacity: 1;
        }
      }
    }
  }
}

.privacypolicy {
  // margin-top: 22rem - ($menubarheightinrem + $navbarheightinrem);

  // @media (max-width: 40em) {
  //   margin-top: 18rem - ($menubarheightinrem + $navbarheightinrem);
  // }

  &__section {
    //background-color: red;
    //height: 100px;
    width: 80%;
    margin: 5rem auto;
    border: 1px solid $dark;
    border-radius: 2rem;
  }
}

.onesectionmultiplepara {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  margin: 3rem auto;
  text-align: center;
  &_title {
    font-size: 3rem;
    font-weight: 500;
    color: $light;
  }
  &_para {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    text-align: justify;
  }
}

////Admin Dashboard
///

.editpopcontactformheaders {
  display: flex;
  justify-content: space-between;
  &__tags {
    display: flex;
    align-items: center;
    &_tag {
      background-color: #1c7c7c;
      color: white;
      padding: 1rem;
      font-size: 1.5rem;
      margin: 0 0.5rem 2rem 0;
      cursor: pointer;
    }
  }
}

.closebuttononfrom {
  font-size: 2rem;
  text-align: right;
  margin-right: 2rem;
  cursor: pointer;
  font-weight: 700;
}

.dashboardnavbar {
  background-color: #043434;
  color: white;
  padding: 3rem;
  text-align: center;
  font-size: 2rem;
}

.dashboardarea {
  display: flex;
}

.dashboarddrawer {
  height: 100%;
  width: 250px;
  background-color: #1c7c7c;
  display: flex;
  flex-direction: column;

  &__a {
    padding: 3rem;
    text-decoration: none;
    color: white;
    font-size: 1.8rem;
    border: 2px solid;
    border-color: #d4f1f4;
    text-align: center;
    cursor: pointer;
    //display: block;
    //transition: 0.3s;
  }

  // .drawer a:hover {
  //     background-color: #ddd;
  // }
}

.dashboardplaygound {
  margin: 5rem;
  width: 100%;
}

.editpop {
  &__primaryarea {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header {
    font-size: 2rem;
    font-weight: 500;
  }

  &__addbutton {
    background-color: #043434;
    padding: 1rem;
    border-radius: 1rem;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
  }
  &__formarea {
    border: 1px solid #1c7c7c;
    height: 100%;
    border-radius: 1rem;
  }
}

table {
  margin-top: 8rem;
  width: 100%;
  font-size: 1.6rem;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.passwordarea {
  display: flex;
  justify-content: center;
  margin-top: 25rem;
}

.password-input {
  width: 300px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #3498db;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.password-input:focus {
  border-color: #e74c3c;
}

.ATFCOEs {
  width: 80%;
  margin: 5rem auto;
  display: flex;
  gap: 2rem;
}

.atfcoecard {
  background-color: white;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 3em 0 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 50%;
  padding: 2rem;

  margin: 1em auto;

  @media (max-width: 40em) {
    width: 80%;
    margin: 1rem auto;
  }
  //height: fit-content;
  //flex-basis: 1;

  &__header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &__img {
      width: 5rem;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $dark;
    //padding: 2rem;
  }
  &__text {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: $dark;
  }

  &__goal {
    display: flex;
    //align-items: center;
    gap: 1rem;
    color: $dark;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    &__textsection {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__label {
      background-color: $medium;
      border-radius: 1rem;
      width: fit-content;
      padding: 0.5rem 1rem;
      color: black;
      font-weight: 500;
      font-size: 1.3rem;
      margin: 1rem 0 2rem 0;
      font-size: "1.5rem";
    }
    &__img {
      width: 5rem;
      border-radius: 0.7rem;
      margin: 2% 0;
    }
  }
}

.atfcoecard:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.atfcoeactivecard {
  background-color: white;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //margin: 3em 0 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  //cursor: pointer;
  width: 70%;
  padding: 2rem;

  margin: 3em auto;

  @media (max-width: 40em) {
    width: 80%;
    margin: 1rem auto;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $dark;
    //padding: 2rem;
  }
  &__header {
    width: 96%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logogroup {
      display: flex;
      width: 90%;
      align-items: center;
    }
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
  }
}

.goalatfcoe {
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //margin: 3em 0 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  //cursor: pointer;
  width: 45%;
  padding: 2rem;
  margin: 3em 2rem;

  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $dark;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 700;
    color: $dark;
    margin-top: 1rem;
  }
}

////Mobility
///

.topborder {
  margin: 0 auto 0 auto;
  margin-top: 22rem - ($menubarheightinrem + $navbarheightinrem);
  width: 100%;
  padding: 0.5rem 0;
  background-color: $primary-medium;
}
.whattm {
  //width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  color: $primary-light;
  transform: translate(-50%, -50%);
  z-index: -10;
  margin-top: 3rem;
  @media (max-width: 40em) {
    transform: translate(-50%, -70%);
  }
  &__header {
    font-size: 3rem;
    font-weight: 600;
    color: #33cbcb;
    //color: $primary-dark2;
    margin: -5rem 0 0rem 0;
    @media (max-width: 40em) {
      margin: 0rem 0 0 0;
      text-align: center;
    }
  }
  &__subheader {
    font-size: 5rem;
    font-weight: 700;
    color: white;
    //color: $primary-dark2;
    margin: 0rem 0 0rem 0;

    @media (max-width: 40em) {
      margin: 1rem 0 0 0;
      text-align: center;
    }
  }
  &__subheader1 {
    font-size: 4rem;
    font-weight: 500;
    color: white;
    text-decoration: solid underline #33cbcb 6px;
    //color: $primary-dark2;
    margin: 0rem 0 2rem 0;
    @media (max-width: 40em) {
      margin: 1rem 0 0 0;
      text-align: center;
    }
  }
  &__subheader2 {
    font-size: 2rem;
    font-weight: 500;
    color: white;
    //color: $primary-dark2;
    margin: 0rem 0 2rem 0;
    @media (max-width: 40em) {
      margin: 1rem 0 0 0;
      text-align: center;
    }
  }
  &__year {
    font-size: 3rem;
    font-weight: 700;
    color: $primary-dark2;
    margin: 0rem 0 4rem 0;
    @media (max-width: 40em) {
      margin: 2rem 0;
    }
  }
  &__applybutton {
    font-size: 3rem;
    background-color: #1c7c7c;
    color: white;
    padding: 1rem 8rem;
    border-radius: 1rem;
    margin-top: 4rem;
    border: 2px solid #33cbcb;
    cursor: pointer;
  }
}

.wheretm {
  display: flex;
  &__logo {
    width: 40%;
    padding: 2rem 0 0 8rem;
    margin-bottom: 1rem;
    @media (max-width: 40em) {
      padding: 3rem 0 0 0;
      text-align: center;
      //padding: 2rem 0 0 0rem;
      margin: 0 auto;
    }
  }
  &__header {
    font-size: 3rem;
    font-weight: 700;
    color: $primary-medium;
    padding: 0rem 0 0 8rem;
    text-align: left;
    //color: $primary-dark2;
    //margin: -5rem 0 0rem 0;
    margin-bottom: 2rem;
    @media (max-width: 40em) {
      padding: 4rem 0 0 0;
      text-align: center;
    }
  }

  &__points {
    padding: 2rem;
    font-size: 2rem;
    padding: 0rem 0 0 8rem;
    text-align: left;
    margin-bottom: 3rem;
    &_point {
      margin-bottom: 0.5rem;
      font-weight: 700;
      color: $primary-dark;
    }
    @media (max-width: 40em) {
      text-align: center;
      padding: 2rem 0 0 0rem;
    }
  }

  &__button {
    background-color: #1c7c7c;
    width: 55%;
    color: white;
    padding: 1rem;
    border-radius: 2rem;
    text-align: center;
    font-weight: 600;
    font-size: 1.7rem;
    margin: 0rem 0 0rem 8rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 40em) {
      //  padding: 9rem 0 0 0;
      // text-align: center;
      // padding: 2rem 0 0 0rem;
      margin: 0 auto;
    }
  }

  &__arrowimg {
    width: 12%;
  }
}

.whytm {
  background-color: $primary-dark;
  color: white;
  padding: 0rem 0 1rem 0;

  &__header {
    width: 55%;
    font-size: 3rem;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    padding: 7rem 0 0rem 0;
  }
}

.howtm {
  position: relative;
  z-index: 1;
  margin: 0 0 9rem 0;
  @media (max-width: 40em) {
    display: none;
  }

  &__headline {
    background-color: $primary-medium;
    margin-top: 4rem;
    width: 80%;
    border-radius: 0rem 2rem 2rem 0rem;
    @media (max-width: 40em) {
      width: 100%;
      border-radius: 0rem;
    }

    &__text {
      color: white;
      font-size: 3rem;
      padding: 4rem 0 20rem 0rem;
      margin: 0 0 0 19rem;
      font-weight: 700;
      @media (max-width: 40em) {
        //margin: 0 0 0 10rem;
        //padding: 4rem 0 10rem 0rem;
        //text-align: center;
      }
    }
  }

  &__SDG {
    margin-left: 82%;
    position: absolute;
    top: 3rem; /* Adjust the top position as needed */
    left: 0;
    &__img {
      width: 6rem;
      margin-left: 1rem;
      @media (max-width: 40em) {
        display: none;
      }
    }
    @media (max-width: 40em) {
      //width: 10%;
      display: none;
    }
  }

  &__cards {
    display: flex;
    margin: 6rem 0 0 19rem;
    position: absolute;
    gap: 2rem;
    top: 50px; /* Adjust the top position as needed */
    left: 50px; /* Adjust the left position as needed */
    z-index: 2;
    @media (max-width: 40em) {
      display: flex;
      flex-direction: column;
      margin-left: 0rem;
      //left: 8rem;
      //top: 10rem;
    }
    &__card {
      background-color: $primary-dark;
      border: 1rem solid &$primary-dark;
      border-radius: 2rem;
      width: 33rem;
      height: 23rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      @media (max-width: 40em) {
        width: 120%;
        height: 100%;
        font-weight: 500;
        font-size: 3rem;
        flex-direction: row;
        padding: 2rem;
      }

      &__header {
        text-align: center;
        padding-top: 2rem;
        color: white;
        font-size: 2.5rem;
        font-weight: 700;
        @media (max-width: 40em) {
          font-size: 2.5rem;
        }
      }

      &__list {
        color: white;
        font-size: 1.7rem;
        @media (max-width: 40em) {
          font-size: 2rem;
        }
      }
    }
  }
}

.howtmmb {
  background-color: $primary-medium;
  display: flex;
  flex-direction: column;
  padding: 5cqb;
  margin-top: 2rem;
  @media (min-width: 40em) {
    display: none;
  }
  &__headline {
    @media (max-width: 40em) {
      width: 100%;
      //background-color: $primary-medium;
      text-align: center;
      margin: 0 0 3rem 0;
    }

    &__text {
      @media (max-width: 40em) {
        //margin: 0 0 0 10rem;
        //padding: 4rem 0 10rem 0rem;
        //text-align: center;
        color: white;
        font-size: 4.5rem;
        //padding: 4rem 0 20rem 0rem;
        //margin: 0 0 0 19rem;
        font-weight: 700;
      }
    }
  }

  &__cards {
    @media (max-width: 40em) {
      display: flex;
      //margin: 6rem 0 0 19rem;
      //display: flex;
      flex-direction: column;
      gap: 2rem;
      //margin-left: 0rem;
      //left: 8rem;
      //top: 10rem;
    }
    &__card {
      @media (max-width: 40em) {
        background-color: $primary-dark;
        border: 1rem solid &$primary-dark;
        border-radius: 2rem;
        //width: 33rem;
        //height: 23rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        //width: 120%;
        //height: 100%;
        flex-direction: row;
        padding: 3rem;
      }

      &__header {
        @media (max-width: 40em) {
          text-align: center;
          padding-top: 2rem;
          color: white;
          font-size: 3rem;
          font-weight: 700;
        }
      }

      &__list {
        @media (max-width: 40em) {
          font-size: 2.2rem;
          color: white;
        }
      }
    }
  }
}

.projectlisttm {
  width: 80%;
  margin: 12rem auto 5rem auto;
  border-radius: 2rem;
  border: 1px solid $primary-dark;
  padding: 2rem;

  @media (max-width: 40em) {
    font-size: 2rem;
    margin: 40rem auto 0 auto;
    width: 80%;
  }

  &__project {
    background-color: transparent;
    color: black;
    padding: 1rem 2rem;
    font-size: 2rem;
    margin: 1rem auto;
    text-align: center;
    width: 90%;
    border-radius: 1rem;
    font-weight: 500;
    border: 1px solid black;
  }

  &__header {
    font-size: 3rem;
    text-align: center;
    margin: 2rem 0;
    font-weight: 700;
    color: $primary-dark;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 40em) {
      flex-direction: column;
    }
  }
}

.week8 {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  align-items: center;
  @media (max-width: 40em) {
    margin: 5rem 0 0 0;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__logo {
      width: 10rem;
    }
    &__week8logo {
      display: flex;
      font-size: 5rem;
      &__firsthalf {
        color: $primary-dark;
        font-weight: 800;
      }
      &__secondhalf {
        font-weight: 800;
        color: $primary-medium;
        margin-left: -0.3rem;
      }
    }
    &__subtitle {
      font-size: 2rem;
      font-weight: 700;
      margin-top: 0.5rem;
      @media (max-width: 40em) {
        font-size: 2.5rem;
        text-align: center;
        width: 80%;
      }
    }
  }

  &__main {
    width: 80%;
    margin-top: 4rem;
    &__cards {
      font-size: 2rem;
      display: flex;
      justify-content: space-evenly;
      gap: 1rem;
      @media (max-width: 40em) {
        display: flex;
        width: 90%;
        flex-direction: column;
        margin: 0 auto;
      }

      &__cardpc {
        display: flex;
        flex-direction: column;
        padding: 3rem;
        background-color: #043434;
        border-radius: 1rem;
        color: white;
        align-items: center;
        text-align: center;
        //height: 100%;
        @media (max-width: 40em) {
          display: none;
        }

        &__icon {
          width: 70%;
        }
        &__title {
          color: white;
        }
      }
      &__cardpc:hover {
        margin-bottom: -3rem;
        @media (max-width: 40em) {
          display: none;
        }
      }
      &__cardmobo {
        display: flex;
        flex-direction: column;
        padding: 3rem;
        background-color: #043434;
        border-radius: 1rem;
        color: white;
        align-items: center;
        text-align: center;
        //height: 100%;
        @media (min-width: 40em) {
          display: none;
        }

        &__icon {
          width: 40%;
        }
        &__title {
          color: #33cbcb;
          @media (max-width: 40em) {
            font-size: 2.5rem;
            font-weight: 500;
          }
        }
      }
    }

    &__body {
      background-color: #043434;
      margin-top: 1rem;
      border-radius: 1rem;
      padding: 2rem;
      font-size: 2rem;
      color: white;

      @media (max-width: 40em) {
        display: none;
      }

      &__des {
        padding: 2rem;
        text-align: center;
        color: white;
        font-size: 2.4rem;
        text-decoration: wavy;
        font-weight: 700;

        &__text {
          //border-bottom: 10px solid #33cbcb;
          //width: 100%;
        }
      }
    }
  }
}

.slidecontainer {
  // position: relative;
  // width: 100%;
  // height: 200px;
  // overflow: hidden;
}
.box {
  /* ... other styles ... */
  //transition: transform 2s ease-in-out;
  margin-top: -25rem;
  //position: absolute;
  transition: transform 1s ease-in-out;
}

.slideLeft1 {
  transform: translateX(0);
}
.slideLeft2 {
  transform: translateX(0%);
}

.projectdetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 5rem auto 3rem auto;
  //color: white;
  background-color: white;
  padding: 2rem;
  //border: 1px solid #043434;
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0.1, 0.1);
  @media (max-width: 40em) {
    text-align: center;
  }
  &__title {
    //color: #043434;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 700;
    @media (max-width: 40em) {
      text-align: center;
    }
  }
  &__para {
    //color: #043434;
    font-size: 2rem;
    text-align: center;
  }
}

.flowoftheprogram {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
  width: 80%;

  @media (max-width: 40em) {
    flex-direction: column;
    align-items: normal;
  }
  &__title {
    font-size: 3rem;
    margin: 2rem 0;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  &__body {
    display: flex;
    //background-color: red;
    gap: 2rem;

    @media (max-width: 40em) {
      flex-direction: column;
    }

    &__card {
      //border: 1px solid black;
      border-radius: 2rem;
      padding: 2rem;
      flex-basis: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      background-color: white;
      @media (max-width: 40em) {
        flex-basis: auto;
      }
      &__header {
        font-size: 2.5rem;
        font-weight: 600;
        @media (max-width: 40em) {
          font-size: 2.7rem;
        }
      }
      &__infocard {
        display: flex;
        flex-direction: column;
        &__first {
          &__jodi {
            display: flex;
            &__title {
              font-size: 1.7rem;
              font-weight: 500;
              @media (max-width: 40em) {
                font-size: 2.2rem;
              }
            }
            &__subtitle {
              font-size: 1.5rem;
              margin-left: 1rem;
              font-weight: 400;
              @media (max-width: 40em) {
                font-size: 2.2rem;
              }
            }
          }
          padding: 1rem 0;
          border-bottom: 1px solid black;
        }
        &__second {
          padding-top: 1rem;
          &__title {
            font-size: 2.5rem;
            font-weight: 500;
            @media (max-width: 40em) {
              font-size: 2.5rem;
            }
          }
          &__objectives {
            margin-top: 1rem;
            font-size: 1.7rem;
            @media (max-width: 40em) {
              font-size: 2.2rem;
            }
          }
        }
      }
    }
  }
}

.brochure {
  position: relative;
  width: 100%;
  height: 100%;
  height: 40vh;
  //z-index: -10;
  //background-color: #043434;
  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    filter: brightness(50%);
    z-index: -1;
    display: flex;
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 40%;
    color: white;
    transform: translate(-30%, -90%);
    text-align: center;
    font-size: 4rem;
    font-weight: 800;
    text-shadow: 2px 2px #043434;
  }
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #043434;
    background-color: #33cbcb;
    transform: translate(-50%, 100%);
    text-align: center;
    font-size: 3rem;
    font-weight: 800;
    padding: 1rem 4rem;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
    @media (max-width: 40em) {
      transform: translate(-50%, 150%);
    }
  }
  &__button:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  }
}

//live dot

.live-icon {
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 20px;
}

.live-text {
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.live-dot {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

//Image Carousel

.image-carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.image-container {
  width: 100%;
  //height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: $medium;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 24px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.image-counter {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
}

.imagecarouselsection {
  border-radius: 10px;
  border: 1px solid #043434;
  align-items: center;
  width: 80%;
  margin: 2rem auto 1rem auto;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  @media (max-width: 40em) {
    //border-color: transparent;
    flex-direction: column;
    //padding: 0rem;
  }

  &__headline {
    width: 75%;
    //background-color: red;
    margin: 2rem auto -3rem auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 40em) {
      //border-color: transparent;
      //flex-direction: column;
      //padding: 0rem;
      text-align: center;
      margin: 3rem auto;
      flex-direction: column;
      align-items: center;
      //justify-content: none;
    }

    &__button {
      display: flex;
      align-items: center;
      @media (max-width: 40em) {
        margin-top: -2rem;
      }

      &__style {
        background-color: $dark;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.5s ease;
        @media (max-width: 40em) {
          //margin-top: -2rem;
          font-size: 12px;
        }
      }
      :hover {
        //background-color: #5289c0;
        box-shadow: 10px 10px 8px rgba(12, 0, 0, 0.2);
      }
    }
  }

  &__section {
    padding: 0rem 4rem 0 2rem;
    width: 50%;
    @media (max-width: 40em) {
      width: 100%;
    }
  }

  &__imgsection {
    display: flex;
    justify-content: left;
    @media (max-width: 40em) {
      justify-content: center;
    }
  }

  &__header {
    font-size: 3rem;
    font-weight: 600;
    color: $primary-medium;

    text-align: left;
    //color: $primary-dark2;
    //margin: -5rem 0 0rem 0;
    margin-bottom: 2rem;
    @media (max-width: 40em) {
      //padding: 4rem 0 0 0;
      text-align: center;
    }
  }
  &__subheader {
    font-size: 2rem;
    font-weight: 700;
    //color: $primary-medium;
    text-align: left;
    //color: $primary-dark2;
    //margin: -5rem 0 0rem 0;
    margin-bottom: 1.3rem;
    @media (max-width: 40em) {
      //padding: 4rem 0 0 0;
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
  }
  &__text {
    font-size: 2rem;
    font-weight: 700;
    //color: $primary-medium;
    text-align: left;
    //color: $primary-dark2;
    //margin: -5rem 0 0rem 0;
    margin-bottom: 2rem;
    @media (max-width: 40em) {
      //padding: 4rem 0 0 0;
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 4rem;
    }
  }
  &__logo {
    width: 24%;
    align-self: center;
    margin-bottom: 3rem;
    margin-right: 2rem;
    @media (max-width: 40em) {
      //padding: 3rem 0 0 0;
      //text-align: center;
      //padding: 2rem 0 0 0rem;
      //margin: 0 auto;
      margin-bottom: 4rem;
      //display: flex;
      //flex-direction: column;
    }
  }
}

//.......linkedin wall
.linkedin-wall {
  margin: 0px 0;
  //padding: 20px 0;
  //background-color: #f3f2ef;
  //display: flex;
}

.linkedin-wall-section {
  background-color: $blue;
  padding: 3rem 3rem;
  //margin: 0 14em;
  //border-radius: 12px;
  //display: flex;
  //flex-direction: column;
}

.linkedin-wall-section-logo-container {
  width: 78%;
  margin: auto;
}
.section-logo {
  background-color: white;
  width: 48px;
  height: 48px;
  border-radius: 15%;
  //margin-right: 12px;

  //text-align: left;
  //font-size: 24px;
  //margin-bottom: 20px;
  //color: white;
  //margin: 0 16rem 1rem 16rem;
  //background-color: red;
  @media (max-width: 40em) {
    // text-align: center;
    // margin: 0 10rem;
    display: block;
    margin: 2rem auto;
  }
}

.sectionname-followbutton-section {
  display: flex;
  justify-content: space-between;
  width: 78%;
  margin: 1rem auto;
  @media (max-width: 40em) {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 2rem auto;
  }
}

.section-title {
  text-align: left;
  font-size: 30px;
  margin-bottom: 20px;
  color: white;
  //margin-left: 6.5em;
  margin: 0 0 0 0;
  //background-color: red;
  @media (max-width: 40em) {
    text-align: center;
    margin: 1rem 0 2rem 0;
  }
}

.linkedin-wall-container {
  display: flex;
  max-width: 80%;
  //overflow-x: auto;
  //padding: 0px;
  //scroll-snap-type: x mandatory;
  //gap: 0px;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
  gap: 3%;
  justify-content: space-evenly;

  flex-wrap: wrap;
  @media (max-width: 50em) {
    flex-direction: column;
    //padding: 0rem;
    //overflow-x: hidden;
    margin: 1em auto;
    align-items: center;
  }
}

.linkedin-card {
  //max-width: 550px;
  //width: 100%;
  //border: 1px solid #e0e0e0;
  border-radius: 12px;
  //overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 2rem auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  //height: 400px; /* Set a fixed height for all cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 350px; /* Reduced from 550px */
  min-width: 250px; /* Ensure cards don't get too small */
  //width: 100%;
  //gap: 4%;

  flex: 1 1 30%;
  @media (max-width: 40em) {
    flex-direction: column;
    padding: 0rem;
    max-width: none;
    flex: 1 1 45%;
  }
}

.linkedin-card-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}

.post-info {
  flex-grow: 1;
}

.author-name {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.post-meta {
  margin: 4px 0 0;
  font-size: 14px;
  color: #666;
}

.linkedin-card-body {
  padding: 16px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.post-headline {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0 0 12px 0;
  line-height: 1.2;
}

.post-content {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}

.post-image {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
}

.post-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.linkedin-card-footer {
  padding: 20px 16px;
  border-top: 1px solid #e0e0e0;
}

.linkedin-link {
  //display: block;
  padding: 8px 16px;
  background-color: #0a66c2;
  color: #fff;
  text-decoration: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  text-align: center;
}

.linkedin-link:hover {
  background-color: #004182;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.linkedin-wall-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.linkedin-wall-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.linkedin-button {
  background-color: white;
  color: $dark;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;
  transition: background-color 0.3s ease;
  // @media (max-width: 40em) {
  //   display: block;
  //   margin: 2rem auto 0 auto;
  // }
}

.linkedin-button:hover {
  //background-color: #5289c0;
  box-shadow: 10px 10px 8px rgba(12, 0, 0, 0.2);
}

/* Media query for mobile devices */

/* for Neural network*/
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65vh;
  padding: 2rem;
  //background-color: #fffae7;
  width: 80%;
  margin: 0 auto;
}

.hero-content {
  width: 45%;
  padding-right: 2rem;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 2rem;
  color: $light;
  line-height: 1.5;
}

.hero-animation {
  width: 50%;
  height: 45rem;
  position: relative;
}

.hero-animation canvas {
  position: absolute;
  padding: 4rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 40em) {
    //background-color: red;
    //width: 100%;
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-content,
  .hero-animation {
    width: 100%;
  }

  .hero-content {
    //padding-right: 0;
    //padding-bottom: 2rem;
    margin: 4rem 0;
  }

  .hero-animation {
    height: 300px;
  }
}

/* FLIGHT insights section */
// FlightinsightsStyles.scss ...............................................................................................................................................................................

.flightinsights-wrapper {
  background-color: #f7efe5;
  margin: 0 auto;
  border-radius: 2rem;
  padding: 2rem 0 0 0;
  position: relative;
  overflow: hidden;
}

// If you need to ensure content is above the background
.flightinsights-content {
  position: relative;
  z-index: 3;
  display: flex;

  @media (max-width: 40em) {
    //background-color: red;
    flex-direction: column;
  }
}

.flightinsights-wrapper-img {
  width: 7rem;
  display: block;
  margin: 0 auto;
}

.flightinsights-container {
  max-width: 80%;
  margin: 0 auto;
  background-color: white;
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0.1, 0.1);
  // filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
  //   drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  overflow: hidden;
}

.flightinsights-navigation {
  background-color: white;
  border-bottom: 1px solid #e5e7eb;

  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  border-radius: 2rem 2rem 0 0;
}

.flightinsights-navigation::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.flightinsights-menu-list {
  width: 90%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  //padding: 1rem 1.5rem;
  list-style-type: none;

  padding: 0.5rem 0; /* Add some vertical padding */

  @media (max-width: 40em) {
    //background-color: red;
    //width: 100%;
    //justify-content: flex-start; /* Align items to the start */
    gap: 1rem; /* Add space between items */
  }
}

.flightinsights-menu-item {
  font-size: 1.8rem;
  font-weight: 500;
  color: $dark;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin: auto 0;

  padding: 1rem 1.5rem;
  border-radius: 2rem;

  white-space: nowrap; /* Prevent text wrapping */

  &:hover {
    color: $medium;
    background-color: rgba(227, 226, 226, 0.5);
  }

  &--active {
    color: $dark;
    //border-bottom: 2px solid $light;
    //CHANGED
    background-color: $vintagewash;
    // padding: 1rem 1.5rem;
    // border-radius: 2rem;
  }

  @media (max-width: 40em) {
    lex: 0 0 auto; /* Allow items to shrink */
  }
}

.flightinsights-content {
  display: flex;
  padding: 3rem 4rem;
  //max-height: 70rem;
  &__image-container {
    width: 100%;
    padding-top: 56.25%; // 16:9 Aspect Ratio (9 / 16 = 0.5625)
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__left {
    margin-bottom: 1rem;
    width: 100%;
  }

  @media (max-width: 40em) {
    padding: 2rem 2rem;
  }
}

.flightinsights-content__left,
.flightinsights-content__right {
  flex: 1;
}

.flightinsights-content__left {
  padding-right: 1rem;
  margin: auto 0;
  @media (max-width: 40em) {
    //background-color: red;
    width: 100%;
  }
}

.flightinsights-content__right {
  padding: 0rem 2rem;
  //height: 30rem;
  margin: auto 0;
  @media (max-width: 40em) {
    //background-color: red;
    margin-top: 3rem;
    padding: 0rem 1rem;
  }
}

.flightinsights-placeholder {
  background-color: #e5e7eb;
  //height: 16rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    color: #6b7280;
  }
}

.flightinsights-title {
  //max-height: 20rem;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  //padding: 1rem;
}

.flightinsights-description {
  color: #6b7280;
  font-size: 1.7rem;
  text-align: justify;
  margin-bottom: 1.5rem;
  //padding: 0 3rem 3rem 3rem;
}

.flightinsights-features {
  display: flex;
  gap: 1rem;
}

.flightinsights-feature {
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    color: $medium;
  }

  &__title {
    font-weight: 600;
  }

  &__description {
    font-size: 0.875rem;
    color: #6b7280;
  }
}

.flightinsights-acronym {
  display: flex;
  justify-content: center;
  //margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: -0.4rem;
  // @media (min-width: $breakpoint-medium) {
  //   font-size: 3rem;
  // }

  &__letter {
    margin: 0 0.25rem;
    transition: color 0.3s ease;

    &--active {
      color: $light;
      //CHANGED
    }
  }
}

.flightinsights-headline {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  color: $dark;
  //background-color: $medium;
}

// .flightinsights {
//   //background-color: $medium;
//   padding: 2rem 0;
//   width: 100%;
// }

.flightinsights-image-slider {
  position: relative;
  width: 100%;
  height: 0;
  //height: 300px;
  padding-top: 56.25%; // 16:9 Aspect ratio
  overflow: hidden;
  border-radius: 2rem;

  @media (max-width: 40em) {
    //background-color: red;
    //margin-top: 3rem;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 18px;
    transition: background 0.3s ease;
    z-index: 10;
    border-radius: 2rem;

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    &--prev {
      left: 10px;
    }

    &--next {
      right: 10px;
    }
  }
}

// .internexperience {
//   display: flex;
//   width: 94%;
//   margin: 0 auto;
//   gap: 1rem;
//   //background-color: #004182;

//   &_videosection {
//     //background-color: white;
//     height: 35rem;
//     //width: 20rem;
//     border-radius: 2rem;
//     margin: 2rem 0;

//     //width: inherit; /* Full width of the viewport */
//     //height: 100%; /* Full height of the viewport */
//     //display: flex;
//     //justify-content: center; /* Center horizontally */
//     //align-items: center; /* Center vertically */
//     //background-color: #000; /* Optional: Black background like Instagram */
//     overflow: hidden;
//     border-radius: 2rem; /* Hide overflow */
//     background-color: transparent;
//   } /* Add this new rule */
// }

// /* Ensure controls are visible */
// video::-webkit-media-controls {
//   display: flex !important;
//   background-color: transparent;
// }

// /* For Firefox */
// .story-video::-moz-media-controls {
//   display: flex !important;
//   background-color: transparent;
// }

// .story-video {
//   width: 100%; /* Full width of the viewport */
//   height: inherit; /* Full height of the viewport */
//   object-fit: cover; /* Scale video to cover the container */
//   background-color: transparent;
//   display: block; /* Removes any potential inline spacing */
// }

.internexperience {
  display: flex;
  width: 70%;
  margin: 3rem auto 0 auto;
  gap: 1rem;
  //z-index: 2;
  justify-content: space-evenly;
  @media (max-width: 40em) {
    //height: 300px;
    border-radius: 2rem;
  }
}

.internexperience-headline {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 4rem 0 1rem 0;
}

.internexperience_videosection {
  height: 35rem;
  border-radius: 2rem;
  //margin: 2rem 0;
  overflow: hidden;
  flex: 0 0 calc(20% - 0.75rem);
  @media (max-width: 40em) {
    border-radius: 2rem;
  }
}

.video-container {
  // position: relative;
  // width: 100%;
  // height: 100%;
  // background-color: transparent !important;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2rem;
}

.image-container-flight {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.video-container::after {
  //content: "";
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background-color: var(--tint-color, rgba(0, 0, 0, 0));
  // pointer-events: none; /* This allows clicks to pass through to the video */
  // z-index: 20;
  // background-color: transparent !important;
}

.story-video {
  //width: 100%;
  //height: 100%;
  //object-fit: cover;
  //border-radius: 2rem;
  //z-index: 20;
  filter: brightness(100%);
  //background-color: transparent !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 40em) {
  }
}

/* Ensure controls are visible */
video::-webkit-media-controls {
  // z-index: 2; /* Ensure controls are above the tint */
  // background-color: transparent !important;
}

/* For Firefox */
.story-video::-moz-media-controls {
  //z-index: 2;
  //background-color: transparent !important;
}

video::-webkit-media-controls-panel {
  // background-color: transparent !important;
  // box-shadow: none !important;
}

.video-player .plyr .plyr__controls:before {
  //background-image: none !important;
}

@media (max-width: 768px) {
  .internexperience {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    padding-bottom: 1rem; /* Add some padding at the bottom */
    width: 80%;

    @media (max-width: 40rem) {
      width: 90%;
      border-radius: 2rem;
    }
  }

  .internexperience::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }

  .internexperience_videosection {
    flex: 0 0 40%; /* Each video takes 80% of the container width */
    margin-right: 1rem; /* Add space between videos */
    height: 45rem;
  }

  .internexperience_videosection:last-child {
    margin-right: 0; /* Remove margin from the last video */
  }
}

/* Adjust video height for smaller screens */
@media (max-width: 480px) {
  .internexperience_videosection {
    //height: 2.5rem;
    height: auto;
    max-width: 100%;
  }
  .internexperience_videosection:first-child {
    margin-left: 1rem; /* add margin from the first video */
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.custom-play-button {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  color: $medium;
}

.custom-play-button:hover {
  opacity: 1;
}

.video-container:hover .video-overlay {
  opacity: 1;
}

.grid-background {
  background-color: $vintagewash; /* Light grey background */
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each grid cell */
}

// Boarding pass -------------------------------------------------------------------------------------------------------------------------
// Global styles
// body {
//   font-family: Arial, sans-serif;
//   margin: 0;
//   padding: 0;
//   background-color: #f0f0f0;
// }
.form-and-pass-container-outer {
  background-color: $vintagewash; /* Light grey background */
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each grid cell */
  padding-bottom: 3rem;
}

.registration-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: "";
    position: absolute;
    bottom: 2px; // Changed from -4px to -1px
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $medium;
  }
}

.form-and-pass-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  //min-height: 100vh;
  //border-radius: 5rem 5rem 0 0;
  // background-color: $vintagewash; /* Light grey background */
  // background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
  //   linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  // background-size: 20px 20px; /* Size of each grid cell */
}

// Split Card styles
.split-card {
  width: 700px;
  background-color: white;
  border-radius: 10px;
  //padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;

  &__cut {
    width: 20px;
    height: 20px;
    background-color: $vintagewash;
    border-radius: 50%;
    position: absolute;

    &--left,
    &--right {
      top: 50%;
      transform: translateY(-50%);
    }

    &--left {
      left: -10px;
    }

    &--right {
      right: -10px;
    }

    &--left-bottom,
    &--right-bottom {
      bottom: 50px;
    }

    &--left-bottom {
      left: -10px;
    }

    &--right-bottom {
      right: -10px;
    }
  }

  &__left,
  &__right {
    flex: 1;
    padding: 3rem;
  }

  &__left {
    background-color: $medium;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem 0 0 1rem;

    .event-image {
      width: 100%;
      object-fit: cover;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
      display: block;
    }
  }

  &__divider {
    border-left: 2px dashed #ccc;
  }

  &__right {
    h2 {
      margin-bottom: 15px;
      color: $dark;
      text-align: center;
      text-transform: uppercase;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .error {
        color: #d32f2f;
        font-size: 14px;
        margin-top: -5px;
        margin-bottom: 5px;
      }

      input,
      select {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
          outline: none;
          border-color: #0056b3;
        }
      }

      input {
        background-image: none;
      }

      select {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-position: right 0.7em top 50%;
        background-size: 0.65em auto;
      }

      .submit-button {
        padding: 10px;
        background-color: $blue;
        color: white;
        border: none;
        border-radius: 1rem;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}

// Boarding Pass styles
.boarding-pass {
  width: 400px;
  //height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 3.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  //font-family: Arial, sans-serif;
  //display: flex;
  //flex-direction: column;
  height: 100%;
  &__cut {
    width: 20px;
    height: 20px;
    background-color: $vintagewash;
    border-radius: 50%;
    position: absolute;

    &--left,
    &--right {
      top: 50%;
      transform: translateY(-50%);
    }

    &--left {
      left: -10px;
    }

    &--right {
      right: -10px;
    }

    &--left-bottom,
    &--right-bottom {
      top: calc(50% + 80px);
    }

    &--left-bottom {
      left: -10px;
    }

    &--right-bottom {
      right: -10px;
    }
  }

  &__logo {
    height: 20px;
    margin-bottom: 4rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  &__cities {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  &__city {
    text-align: center;
  }

  &__city-code {
    font-size: 36px;
    font-weight: bold;
  }

  &__city-name {
    font-size: 14px;
    color: #666;
  }

  &__flight-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 14px;
  }

  &__date,
  &__flight-time-label {
    color: #666;
    margin-bottom: 5px;
  }

  &__time,
  &__flight-time {
    font-weight: bold;
  }

  &__passenger-info {
    border-top: 1px solid #ddd;
    padding-top: 20px;
    margin-bottom: 25px;
  }

  &__passenger-label {
    color: #666;
    margin-bottom: 5px;
    font-size: 14px;
  }

  &__passenger-name {
    font-weight: bold;
    font-size: 2.4rem;
  }

  &__info-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    font-size: 14px;
  }

  &__info-label {
    color: #666;
    margin-bottom: 5px;
  }

  &__info-value {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__barcode {
    height: 50px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    margin-top: 3.2rem;
  }

  &__barcode-line {
    height: 100%;
    background-color: #000;
  }

  &__barcode-space {
    height: 100%;
    background-color: #fff;
  }
}

// Media query for responsiveness
@media (max-width: 1200px) {
  .form-and-pass-container {
    flex-direction: column;
    align-items: center;
  }

  .split-card,
  .boarding-pass {
    width: 90%;
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .split-card {
    flex-direction: column;

    &__divider {
      width: 100%;
      height: 0;
      border-left: none;
      border-top: 2px dashed #ccc;
      margin: 20px 0;
    }
  }
}

//--------------//--------------//----------------
//checks background
.FLIGHTPage {
  background-color: $vintagewash;
  margin-top: -5rem;

  background-color: $vintagewash; /* Light grey background */
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each grid cell */
}

// .FLIGHTPage__hero {
//   display: flex;
//   flex-direction: column;
//   width: 80%;
//   margin: 0 auto;
//   justify-content: space-around;
//   //min-height: 100vh;
//   &__content {
//     //flex: 1;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 2rem;
//     //margin: 5rem auto;
//     //background-color: red; // Light gray background, adjust as needed
//   }

//   &__title {
//     font-size: 2.5rem;
//     font-weight: bold;
//     text-align: center;
//     margin-bottom: 1.5rem;
//   }

//   &__button {
//     padding: 0.75rem 1.5rem;
//     font-size: 1rem;
//     background-color: #007bff; // Blue color, adjust as needed
//     color: white;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//     transition: background-color 0.3s ease;

//     &:hover {
//       background-color: #0056b3; // Darker blue on hover
//     }
//   }

//   &__video {
//     //flex: 1;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     //background-color: #000; // Black background for video section
//     color: white; // White text color for placeholder

//     &__videosection {
//       filter: brightness(100%);
//       //width: 80%;
//     }
//   }
// }

.flight-mobility-hero {
  display: flex;
  //padding: 2rem;
  //min-height: 100vh;
  width: 80%;
  margin: 5rem auto 0 auto;
  padding: 5rem 0;
}

.flight-mobility-hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 40em) {
    //background-color: red;
    text-align: center;
    margin-bottom: 2.5rem;
  }
}

.flight-mobility-hero-content-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0rem;
  position: relative;
  @media (max-width: 40em) {
    //background-color: red;
  }
}

.highlighter {
  position: absolute;
  height: 12px;
  background-color: $medium;
  bottom: 4px;
  left: 0;
  width: 300px;
  z-index: -1;

  @media (max-width: 40em) {
    display: none;
  }
}

.flight-mobility-hero-content-title-subtitle {
  font-size: 2rem;
  color: #666;
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.features {
  margin-bottom: 2rem;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feature .icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.primary-button,
.secondary-button {
  font-size: 2rem;
  padding: 1rem 3rem;
  border-radius: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  @media (max-width: 40em) {
    margin: 0 auto;
  }
}

.primary-button {
  background-color: $blue;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: $light;
}

.secondary-button {
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.secondary-button:hover {
  background-color: #f8f8f8;
}

.secondary-button .icon {
  margin-right: 0.5rem;
}

.video-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 40em) {
    //height: 900px;
  }

  &__videosection {
    filter: brightness(100%);
    height: 100%;
  }
}

.img-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 2rem;
  overflow: hidden;
  @media (max-width: 40em) {
    //height: 900px;
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 560px;
  aspect-ratio: 16 / 9;

  // position: relative;
  // padding: 10px;
  // border: 2px solid #ffd700;
  // border-radius: 15px;
}

.video-wrapper::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid $medium;
  border-radius: 15px;
  z-index: -1;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(255, 20, 20, 0.1);
}

@media (max-width: 768px) {
  .flight-mobility-hero {
    flex-direction: column;
  }

  .video-container {
    margin-top: 2rem;
  }
  .video-wrapper::before {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
  }
}

//------------//------------//------------//------------//------------//------------//------------
.brochure-contact {
  display: flex;
  width: 80%;
  justify-content: space-between;
  //max-width: 1200px;
  margin: 0 auto;
  //padding: 2rem;
  //background-color: 5f5f5;

  &__section {
    //flex: 1;
    //padding: 1.5rem;
    //background-color: #ffffff;
    //border-radius: 8px;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &--brochure {
      //margin-right: 1rem;
    }

    &--contact {
      margin-left: 1rem;
    }
  }

  &__image-container {
    position: relative;
    //margin-bottom: 1rem;
    height: 350px;
    overflow: hidden;
    background-color: transparent;
    //margin-bottom: -3rem;
    @media (max-width: 80em) {
      // lex: 0 0 auto; /* Allow items to shrink */
      height: 200px;
      display: none;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    object-fit: cover;

    &--cover {
      position: absolute;
      top: 10;
      left: 80;
      width: 100%;
      z-index: 1;
      //margin-bottom: 92rem;
    }

    &--preview {
      position: absolute;
      top: 20px;
      right: 0;
      width: 50%;
      z-index: 2;
    }

    &--team {
      width: 90%;
      height: 90%;
      object-fit: cover;
      display: flex;
      margin: 0 auto;
    }
  }

  &__textcontainer {
    background-color: white;
    padding: 4rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }

  &__description {
    font-size: 1.7rem;
    color: #666;
    margin-bottom: 1.5rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5rem;
  }

  &__input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 2rem;

    &--textarea {
      min-height: 100px;
      resize: vertical;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
      margin-left: 0.5rem;
    }

    &--primary {
      background-color: $blue;
      color: #ffffff;
      border: none;

      &:hover {
        background-color: $light;
      }
    }

    &--secondary {
      background-color: #ffffff;
      color: #333;
      border: 2px solid #333;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  &__buttoncontact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    //background-color: cadetblue;
    margin-top: 5rem;

    svg {
      margin-left: 0.5rem;
    }

    &--primary {
      background-color: $blue;
      color: #ffffff;
      border: none;

      &:hover {
        background-color: $light;
      }
    }

    &--secondary {
      background-color: #ffffff;
      color: #333;
      border: 2px solid #333;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

@media (max-width: 768px) {
  .brochure-contact {
    flex-direction: column;

    &__section {
      margin: 1rem 0;

      &--brochure,
      &--contact {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__image-container {
      height: auto;
    }

    &__image {
      &--cover,
      &--preview {
        position: static;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.contactussection_area {
  background-color: white;
  border-radius: 2rem;
  margin-left: 1rem;
  padding: 2rem;
}

//-----//-----//-----//-----//-----//-----
.card-container {
  position: relative;
  //border-radius: 2rem;
}

.open-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.floatingcard {
  position: relative;
  width: 90%;
  max-width: 90%;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  //border-radius: 10rem;
  overflow: hidden;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }

  .floatingcard-content {
    //margin-top: 40px;
    //border-radius: 2rem;
    overflow-y: auto;
    flex-grow: 1;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow-y: auto;
  padding: 20px 0;
}

.floatingcard-content {
  //margin-top: 20px;

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        font-weight: bold;
      }

      input,
      textarea {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;

        &.error {
          border-color: #ff0000;
        }
      }

      .error-message {
        color: #ff0000;
        font-size: 12px;
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    .submit-button {
      padding: 10px 20px;
      background-color: $blue;
      color: white;
      border: none;
      border-radius: 1rem;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #218838;
      }
    }
  }
}

@media (max-width: 600px) {
  .floating-card {
    width: 95%;
    padding: 15px;
  }
}

/// registration form ////////-----
///

/* RegistrationForm.scss */

// Variables
$primary-color: #4a90e2;
$error-color: #e74c3c;
$background-color: #f5f5f5;
$border-color: #ddd;
$card-background: #ffffff;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin input-styles {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  transition: border-color 0.3s ease;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: $primary-color;
  }
}

.registration-form-body {
  background-color: $vintagewash; /* Light grey background */
  background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; /* Size of each grid cell */
  height: 100%;
  min-height: 100vh;
}

// Styles
.registration-form {
  max-width: 1200px;
  margin: 0rem auto;
  padding: 5rem 4rem 10rem 4rem;
  background-color: transparent;
  border-radius: 0.5rem;

  .form-title {
    text-align: center;
    color: $light;
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: 700;
    padding-bottom: 3rem;
  }

  .form-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  .form-card {
    flex-basis: calc(50% - 0.5rem);
    background-color: $card-background;
    border-radius: 1rem;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .card-title {
      background-color: $light;
      color: #ffffff;
      padding: 1rem;
      margin: 0;
      font-size: 2rem;
      font-weight: 600;
    }

    .card-content {
      padding: 1rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    .form-group {
      flex: 1;
    }
  }

  .form-group label {
    font-size: 1.5rem; // Adjust this value as needed
  }

  .form-group {
    margin-bottom: 1rem;
    padding: 0 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: $dark;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="file"],
    textarea {
      @include input-styles;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    textarea {
      height: 100px;
      resize: vertical;
      border-radius: 1rem;
    }

    input[type="file"] {
      padding: 0.25rem;
      font-size: 0.875rem;
      border: 1px solid $border-color;
      border-radius: 0.5rem;
      background-color: #f8f8f8;
    }
    input,
    select {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:focus {
        outline: none;
        border-color: #0056b3;
      }
    }
  }

  .error-message {
    color: $error-color;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }

  .submit-button {
    @include flex-center;
    width: 20%;
    padding: 1rem;
    background-color: $blue;
    color: #fff;
    border: none;
    border-radius: 1rem;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 2rem 0 0 auto;

    &:hover {
      background-color: darken($light, 10%);
    }
  }
}

// Responsive styles
@media (max-width: 1024px) {
  .registration-form {
    padding: 5rem;

    .form-card {
      flex-basis: 100%;
    }
    .form-title {
      font-size: 4rem;
    }

    .form-card {
      .card-title {
        font-size: 3rem;
      }
    }

    .form-row {
      flex-direction: column;
      gap: 0;
    }

    .form-group {
      margin-bottom: 0.75rem;

      label {
        font-size: 2rem;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="file"],
      textarea {
        font-size: 0.875rem;
        padding: 0.4375rem;
        font-size: 2rem;
      }
    }

    .submit-button {
      @include flex-center;
      width: 50%;
      padding: 1.5rem;
      background-color: $blue;
      color: #fff;
      border: none;
      border-radius: 1rem;
      font-size: 3rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin: 2rem 0 0 auto;

      &:hover {
        background-color: darken($light, 10%);
      }
    }
  }
}

@media (max-width: 768px) {
  .registration-form {
    padding: 5rem;

    .form-title {
      font-size: 4rem;
    }

    .form-card {
      .card-title {
        font-size: 3rem;
      }
    }

    .form-row {
      flex-direction: column;
      gap: 0;
    }

    .form-group {
      margin-bottom: 0.75rem;

      label {
        font-size: 2rem;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="file"],
      textarea {
        font-size: 0.875rem;
        padding: 0.4375rem;
        font-size: 2rem;
      }
    }

    .submit-button {
      @include flex-center;
      width: 100%;
      padding: 1.5rem;
      background-color: $blue;
      color: #fff;
      border: none;
      border-radius: 1rem;
      font-size: 3rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin: 2rem 0 0 auto;

      &:hover {
        background-color: darken($light, 10%);
      }
    }
  }
}

.custom-select {
  appearance: none;
  //background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 9px 35px 9px 10px;
  font-size: 1.3rem;
  width: 100%;
  cursor: pointer;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 20px auto;

  &:hover {
    border-color: #999;
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e8e8e8;
  }

  input[type="file"] {
    display: none;
  }
}

.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

.error-message {
  color: #ff0000;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

.warning-message {
  &__success {
    background-color: rgb(201, 251, 205);
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1rem;

    @media (max-width: 40em) {
      background-color: rgb(201, 251, 205);
      font-size: 2rem;
      padding: 1rem 2rem;
      border-radius: 1rem;
    }
  }

  &__error {
    background-color: rgb(251, 203, 201);
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
    @media (max-width: 40em) {
      background-color: rgb(251, 203, 201);
      font-size: 2rem;
      padding: 1rem 2rem;
      border-radius: 1rem;
    }
  }
}

.feedbackCard {
  width: 50%;
  margin: 0 auto;
  @media (max-width: 40em) {
    //background-color: red;
    margin-top: 3rem;
    width: 90%;
  }
}

.flightcohort {
  width: 80%;
  margin: 0 auto;
  //display: flex;
  //flex-direction: column;

  //background-color: red;
  &__text {
    &__headline {
      width: 100%;
      text-align: center;
      font-size: 3rem;
      font-weight: 700;
      margin: 2rem 0 3rem 0;
      &__underline {
        width: 40%;
        background-color: $medium;
        height: 7px;
        margin-top: -7px;
        margin: -8px auto;
      }
    }

    &__subheadline {
      text-align: center;
      font-size: 2rem;
    }
  }

  &__intern {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 40rem) {
      flex-direction: column;
    }

    //card
    &__card {
      background-color: white;
      // border-radius: 1rem;
      width: 25rem;
      background-color: white;
      border-radius: 1rem;
      padding: 0 1rem 1rem 1rem;
      //height: 16rem;
      margin: 1rem 1rem 1rem 1rem;
      margin-bottom: 1rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      @media (max-width: 40rem) {
        //flex-direction: column;
        width: 80%;
        margin: 2rem auto;
        padding: 1rem 0 3rem 0;
      }

      &__imgsection {
        display: flex;
        justify-content: center;
        &__img {
          width: 20rem;
        }
      }

      &__infosection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // background-color: white;
        // border-radius: 1rem;
        // padding: 1rem;
        // height: 16rem;

        &__name {
          font-size: 1.9rem;
          font-weight: 600;
          @media (max-width: 40rem) {
            font-size: 3rem;
          }

          &__underline {
            width: 100%;
            background-color: $medium;
            height: 7px;
            margin-top: -7px;
          }
        }
        &__project {
          font-size: 1.3rem;
          width: 90%;
          text-align: center;
          margin: 1.4rem 0;
          font-weight: 500;
          color: $dark;
          @media (max-width: 40rem) {
            font-size: 2rem;
          }
        }
        &__professor {
          font-size: 1.5rem;
          font-weight: 500;
          @media (max-width: 40rem) {
            font-size: 1.9rem;
          }
        }
      }
    }
  }
}

// additional fields on registration form

.custom-datepicker {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.4rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #0056b3;
  }
}

.radio-group {
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;

  label {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: normal;
    cursor: pointer;
  }

  input[type="radio"] {
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;

  input[type="checkbox"] {
    margin-top: 0.3rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  .checkbox-text {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.5;
  }
}

@media (max-width: 768px) {
  .custom-datepicker {
    font-size: 1.8rem;
    padding: 1rem;
  }

  .radio-group {
    gap: 3rem;

    label {
      font-size: 1.8rem;
    }
  }

  .checkbox-label {
    input[type="checkbox"] {
      width: 2rem;
      height: 2rem;
    }

    .checkbox-text {
      font-size: 1.8rem;
    }
  }

  .form-group h4 {
    font-size: 2.2rem;
    margin: 2rem 0 1rem 0;
    color: $dark;
  }
}

.form-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .form-card {
    flex: 1 1 calc(50% - 1rem);
    min-width: 300px;
  }
}

.card-content {
  .form-group {
    textarea {
      min-height: 100px;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-cards {
    gap: 1.5rem;

    .form-card {
      flex: 1 1 100%;
    }
  }
}

.form-row {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;

  .form-group {
    flex: 1;
    min-width: 0; /* Add this */

    input[type="number"],
    select {
      width: 100%;
      min-width: 0; /* Add this */
    }
  }
}

.checkbox-label input[type="checkbox"] {
  margin-top: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  accent-color: #1c7c7c; /* This colors the checkmark */
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #1c7c7c;
  border-radius: 4px;
  position: relative;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #1c7c7c;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: "✓";
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
}
input[type="radio"],
input[type="checkbox"] {
  margin-top: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  accent-color: #1c7c7c;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #1c7c7c;
  border-radius: 4px;
  position: relative;
  background-color: white;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: #1c7c7c;
}

input[type="radio"]:checked::after,
input[type="checkbox"]:checked::after {
  content: "✓";
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
}

/* Radio button specific style */
input[type="radio"] {
  border-radius: 50%;
}

/* Remove the dot and keep checkmark for radio buttons */
input[type="radio"]:checked::after {
  content: "✓";
  width: auto;
  height: auto;
  background-color: transparent;
  border-radius: 0;
}

//modern calendar

// Add these styles to your existing SCSS file

.modern-calendar {
  @apply bg-white rounded-lg shadow-lg border-none p-4;
  font-family: inherit;

  .react-datepicker__header {
    @apply bg-transparent border-none pb-4;
  }

  .react-datepicker__current-month {
    @apply text-lg font-semibold text-gray-800 mb-4;
  }

  .react-datepicker__day-name {
    @apply text-sm text-gray-500 w-8 h-8 leading-8 m-1;
  }

  .react-datepicker__day {
    @apply w-8 h-8 leading-8 text-gray-700 rounded-full m-1 hover:bg-cyan-50 transition-colors;

    &--selected {
      @apply bg-cyan-500 text-white hover:bg-cyan-600;
    }

    &--keyboard-selected {
      @apply bg-cyan-100 text-cyan-900;
    }

    &--today {
      @apply font-bold text-cyan-500;
    }

    &--outside-month {
      @apply text-gray-300;
    }
  }

  .react-datepicker__navigation {
    @apply top-4;

    &-icon {
      @apply before:border-gray-400;
    }
  }

  .react-datepicker__year-dropdown {
    @apply bg-white rounded-lg shadow-lg border-none w-1/2 left-1/4;
  }

  .react-datepicker__year-option {
    @apply py-2 px-4 hover:bg-cyan-50 text-gray-700;

    &--selected {
      @apply bg-cyan-500 text-white;
    }
  }
}

.modern-calendar-popper {
  @apply z-50;
}

.react-datepicker-popper {
  @apply z-50;
}

.react-datepicker-wrapper {
  @apply w-full;
}
