$dark: rgb(2, 27, 25);

$primary-dark: rgb(1, 51, 51);
$primary-dark2: rgb(1, 51, 51);
$primary-medium: rgb(28, 126, 116);

$primary-light: rgb(50, 203, 203);
$primary-superlight: #00444535;
$primary-yellow: #ffd800;

$dark: #043434;
$medium: #33cbcb;
$light: #1c7c7c;
$lighter: #d4f1f4;

$vintagewash: #f7efe5;
$blue: #015a9a;

// Variables
$primary-color: rgb(1, 51, 51);
$secondary-color: rgb(28, 126, 116);
$text-dark: rgb(1, 51, 51);
$text-light: rgb(28, 126, 116);
$white: #ffffff;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin gradient-text {
  background: linear-gradient(to right, $primary-color, $secondary-color);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.university-collab {
  width: 100%;
  padding: 6rem 0rem 3rem 0;
  background: #f7efe5;
  &__container {
    max-width: 80%;
    margin: 0 auto;
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    //@include gradient-text;
  }

  &__subtitle {
    text-align: center;
    color: $text-light;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
}

.university-tabs {
  @include flex-center;
  gap: 2rem;
  margin-bottom: 3rem;

  &__button {
    position: relative;
    padding: 1rem 2rem;
    transition: all 0.3s;
    border: none;
    background-color: transparent;
    &:hover {
      transform: scale(1.05);
    }

    &.active {
      transform: scale(1.05);

      .university-tabs__text {
        color: $primary-color;
      }

      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primary-color;
      transform: scaleX(0);
      transition: transform 0.3s;
    }
  }

  &__text {
    font-size: 2rem;
    font-weight: 600;
    color: gray;
    transition: color 0.3s;
  }
}

.university-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    grid-template-columns: 6fr 7fr;
  }
}

.university-image {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  //height: 24rem;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }

  &__location {
    @include flex-center;
    justify-content: flex-start;
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__college {
    color: rgba($white, 0.9);
  }
}

.university-details {
  background: $white;
  border-radius: 2rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.program-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  &__item {
    @include flex-center;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 2rem;
    text-align: center;

    &--program {
      background: #f7efe5;
    }
    &--duration {
      background: #f7efe5;
    }
    &--fee {
      background: #f7efe5;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    color: $primary-color;
  }

  &__label {
    font-size: 1.6rem;
    color: $text-light;
    margin-bottom: 0.25rem;
  }

  &__value {
    font-size: 1.6rem;
    font-weight: 600;
    color: $text-dark;
  }

  @media (max-width: 40em) {
    .program-stats__value {
      font-size: 2.2rem;
    }
    .program-stats__label {
      font-size: 2.2rem;
    }
  }
}

.program-features {
  &__title {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $text-dark;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @media (max-width: 40em) {
    .program-features__title {
      font-size: 3rem;
      text-align: center;
      margin: 3rem 0 2rem 0;
      //background-color: red;
    }
  }
}

.feature-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to right, #f9fafb, $white);
  border: 1px solid #f3f4f6;
  border-radius: 1rem;

  &__dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.75rem;
    background: linear-gradient(to right, $primary-color, $secondary-color);
  }

  &__text {
    color: $text-dark;
    font-weight: 600;
    font-size: 1.4rem;
    @media (max-width: 40em) {
      // background-color: red;
      // font-size: 3rem;
    }
  }

  @media (max-width: 40em) {
    .feature-item__text {
      font-size: 2.2rem;
      //margin: o auto;
      //background-color: red;
    }
  }
}

.university-nav {
  @include flex-center;
  gap: 0.5rem;
  margin-top: 2rem;
  background-color: red;
  &__dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #e5e7eb;
    transition: background-color 0.3s;

    &:hover {
      background: #93c5fd;
    }

    &.active {
      background: $primary-color;
    }
  }
}

.college-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__logo {
    max-width: 150px;
    height: auto;
    margin-bottom: 1rem;
    object-fit: contain;
  }

  @media (max-width: 40em) {
    .college-info__name {
      font-size: 2.2rem;
    }
    .college-info__logo {
      max-width: 190px;
    }
  }

  &__name {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #2c3e50;
    @media (max-width: 40em) {
      //background-color: red;
      font-size: 8rem;
    }
  }

  @media (max-width: 768px) {
    &__logo {
      max-width: 150px;
    }

    &__name {
      font-size: 1.1rem;
    }
  }
}
