.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animations */

/* .imgsection {
  border: 1px solid black;
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  position: absolute;
}

.circleAnimation {
  background-color: red;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;

  -webkit-animation: cirlce 10s linear infinite;
  animation: circle 10s linear infinite;
} */

/* @-webkit-keyframes circle {
  0% {
    transform: rotate(0deg) translate(-165px) rotate(0deg);
  }
   100% {
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  } 
} */

/* @keyframes circle {
  0% {
    transform: rotate(0deg) translate (-165px) rotate (0deg);
  }
  100% {
    transform: rotate(360deg) translate (-165px) rotate (-360deg);
  }
  /* 100% {
    transform: rotate(-360deg);
  } */
.collaboration {
}
.collaborationicons {
  object-fit: cover;
  width: 100%;
}
.imgsection {
  /* border: 2px dotted black; */
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  position: relative;
  margin: 10rem 0;
  @media (max-width: 40em) {
    margin-top: 10rem;
  }
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
  /* s */
}
.circleAnimation4 {
  width: 18rem;
  height: 18rem;
  /* background-color: rgb(1, 51, 51); */
  background-image: url("./Images/ATF SDG Animation image .png");
  background-size: cover;
  /* animation: rotate 10s linear reverse infinite; */
  /* animation-delay: 2s; */
  border-radius: 50%;
  /* overflow: hidden; */
  /* margin: auto; */
  animation-delay: 8.9s;
  margin: -14rem 5.5rem;
  z-index: 1;
  position: absolute;
}
.circleAnimation1 {
  width: 10rem;
  height: 10rem;
  background-color: rgb(1, 51, 51);
  background-image: url("./Images/industry.png");
  background-size: cover;
  /*object-fit: cover;
  object-position: top; */
  animation: rotate 10s linear reverse infinite;
  animation-delay: 2s;
  border-radius: 50%;
  /* overflow: hidden; */
  margin: 10rem auto 0 auto;
}
.circleAnimation3 {
  width: 10rem;
  height: 10rem;
  background-color: rgb(1, 51, 51);
  background-image: url("./Images/researchers.png");
  background-size: cover;
  animation: rotate 10s linear reverse infinite;
  animation-delay: 2s;
  border-radius: 50%;
  /* overflow: hidden; */
  /* margin: auto; */
  animation-delay: 8.9s;
  margin: -10rem auto 0 auto;
}
.circleAnimation2 {
  width: 10rem;
  height: 10rem;
  background-color: rgb(1, 51, 51);
  background-image: url("./Images/institutions.png");
  background-size: cover;
  animation: rotate 10s linear reverse infinite;
  animation-delay: 2s;
  border-radius: 50%;
  /* overflow: hidden; */
  /* margin: auto; */
  animation-delay: 5.5s;
  margin: -10rem auto 0 auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate(-15rem) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-15rem) rotate(-360deg);
  }
}
