//basic settings
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; //1rem = 10px  10px/16px = 62.5

  @media (max-width: 1100px) {
    font-size: 50%;
  }
  @media (max-width: 900px) {
    font-size: 40%;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}

//variables

$primary-dark: #004445;
$primary-dark2: #084c3f;
$primary-medium: #184d47;
$primary-light: #93bea6;
$primary-superlight: #00444535;
$primary-yellow: #ffd800;

//--------------------------------------------- header ---------------------------------------------------//

//navbar
.user-nav {
  display: flex;
  justify-content: space-between;
  padding: 3rem 7rem;
  background-color: transparent;
  width: 100%;

  &__logo-box {
    &__logo {
      cursor: pointer;
      color: black;
      font-size: 4rem;
      font-weight: 700;
    }
  }

  &__buttons {
    display: grid;
    place-items: center;
    color: black;
    font-size: 1.8rem;
    padding: 1rem;
    cursor: pointer;

    &_text {
      width: 100%;
      font-weight: bolder;
      cursor: pointer;
      justify-content: center;
    }

    &_button {
      padding-right: 6rem;
      cursor: pointer;
      // @media (max-width: 650px) {
      //     display: none;
      // }
    }

    &_menu {
      font-size: 3rem;
      display: none;
      @media (max-width: 650px) {
        // display: flex;
        font-size: 5rem;
      }
    }
  }
}

//background-image
.container {
  position: relative;
  //height: 85vh;
  //clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 73%, 0 100%);
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -10;

  &__content {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

// .bg-first {
//   background-color: white;
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   z-index: -10;

//   &__contect {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//     object-position: top;
//   }
// }

.card-container {
  &__card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 70%;
    height: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 4rem;

    @media (max-width: 900px) {
      height: 50%;
      width: 80%;
    }
    @media (max-width: 650px) {
      width: 80%;
      height: 45%;
    }
    @media (max-width: 430px) {
      width: 80%;
      height: 45%;
    }

    &__title {
      text-transform: uppercase;
      font-size: 4rem;
      text-align: center;
      color: white;

      @media (max-width: 600px) {
        font-size: 3rem;
      }
      @media (max-width: 430px) {
        font-size: 3rem;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 2rem;

      @media (max-width: 900px) {
        font-size: 3rem;
      }
      @media (max-width: 650px) {
        font-size: 2.6rem;
      }
      @media (max-width: 430px) {
        font-size: 2.5rem;
      }

      &-button {
        padding: 1rem 4rem;
        background-color: white;
        color: $primary-dark;
        border: 1px solid $primary-dark;
        border-radius: 1rem;
        width: 30%;
        font-size: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        @media (max-width: 430px) {
          width: 50%;
        }

        &:not(:last-child) {
          margin-right: 4rem;
        }

        &-icon {
          margin-right: 1rem;
          @media (max-width: 650px) {
            display: none;
          }
          @media (max-width: 430px) {
            display: none;
          }
        }

        &:hover {
          transform: translateY(-5px);
          transition: all 0.2s;
        }
        &:active {
          transform: translateZ(5px);
        }
      }
    }
  }
}

//new edit
.first-content {
  display: flex;
  justify-content: space-evenly;
  font-size: 2rem;
  //height:0%;
  margin: 0 0 3% 0;

  @media (max-width: 650px) {
    flex-direction: column;
    height: 100%;
  }
  &__card {
    background-color: white;
    width: 40%;
    padding: 2rem;
    flex-direction: column;
    @media (max-width: 650px) {
      width: auto;
    }
    //border-radius: 1rem;
    //--tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    //box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    &__header {
      padding-top: 2%;
      padding-bottom: 2%;
      font-size: 4rem;
      text-align: left;
      font-weight: 900;
      line-height: 1;
      margin-top: 20%;

      @media (max-width: 650px) {
        padding-top: 5%;
        //width: 100%;
        text-align: center;
        margin-top: 0%;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
        margin-top: 0%;
      }

      &__taglinelg {
        // font-size: 2rem;

        // margin-bottom: 1%;
        height: 10rem;
        @media (max-width: 650px) {
          display: none;
        }
      }
      &__taglinesm {
        // font-size: 2rem;

        // margin-bottom: 1%;
        height: 7rem;
        margin: 1%;
        @media (min-width: 650px) {
          display: none;
        }
      }
    }
    &__content {
      // padding-top: 2%;
      // padding-bottom: 2%;
      font-size: 2rem;
      text-align: left;
      font-weight: 500;
      //color: gray;

      @media (max-width: 650px) {
        padding-top: 2%;
        font-size: 2rem;
        text-align: justify;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
      }
    }
    &__button {
      padding: 1rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-top: 4rem;
      background-color: $primary-dark2;
      color: white;
      cursor: pointer;
      border: 1px solid $primary-dark;
      width: 50%;
      font-size: inherit;
      justify-content: center;
      text-align: center;
      border-radius: 8px;

      //align-items: center;
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 650px) {
        font-size: 2.5rem;
        width: 60%;
        margin-left: 20%;
      }
      @media (max-width: 430px) {
      }

      &:not(:last-child) {
        margin-right: 4rem;
      }

      &:hover {
        transform: translateY(-5px);
        transition: all 0.1s;
        //color: $primary-dark;
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
    &__imageDiv {
      position: relative;
      width: 100%;
      //max-width: 100rem;
      //min-width: 60rem;
      display: flex;
      justify-content: center;
      //height: 50rem;
    }
    &__logo {
      width: 20%;
      cursor: pointer;
      height: 90%;
      @media (max-width: 650px) {
        width: 40%;
        height: 80%;
      }
      @media (max-width: 430px) {
        width: 40%;
        height: 80%;
      }
    }
    &__studentImg {
      width: 80%;
      height: 80%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
      @media (max-width: 430px) {
      }
    }
    &__universityImg {
      width: 70%;
      height: 80%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
      @media (max-width: 430px) {
      }
    }
  }
}
//--------------------------------------------- Second Section ---------------------------------------------------//

.SecondSection {
  width: 100%;

  &__title {
    padding-top: 0%;
    font-size: 3rem;
    font-weight: 600;
    //margin-left: 08%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    text-align: center;
    font-weight: 500;

    @media (max-width: 650px) {
      padding-top: 5%;
    }
    @media (max-width: 430px) {
      padding-top: 5%;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-evenly;
    height: 26rem;
    margin: 3% 0;
    @media (max-width: 650px) {
      flex-wrap: wrap;
      height: auto;
    }

    &-card {
      background-color: white;
      width: 18%;
      height: 100%;
      padding: 2rem;
      border-radius: 1rem;
      --tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 650px) {
        width: 40%;
        margin: 2rem;
        height: 100%;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      &-icon {
        font-size: 6rem;
        color: $primary-medium;
      }

      &-title {
        font-size: 1.7rem;
        font-weight: 700;
        @media (max-width: 650px) {
          padding-bottom: 1rem;
        }
      }

      &-info {
        font-size: 1.5rem;
        color: gray;
        text-align: center;
      }

      &:hover {
        transition: all 0.2s;
        --tw-shadow: 0 25px 50px -3px rgba(0, 0, 0, 0.25);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &__button {
      width: 40%;
      padding: 1rem;
      background-color: $primary-dark2;
      color: white;
      border: 1px solid $primary-dark;
      font-size: 2rem;
      justify-content: center;
      align-items: center;
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
}

//--------------------------------------------- New Third Section ---------------------------------------------------//

.programsection {
  display: flex;
  justify-content: space-evenly;
  font-size: 2rem;
  height: 50rem;
  margin-top: 2%;
  width: 100%;

  @media (max-width: 650px) {
    flex-direction: column;
    height: 100%;
  }

  &__header {
    background-color: $primary-dark;
    //background-color: gray;
    width: fit-content;
    color: white;
    margin: auto;
    font-size: 2.4rem;
    border-radius: 5px;
    font-weight: 600;
    padding: 0.7% 3% 0.7% 3%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-weight: 700;

    @media (max-width: 650px) {
      padding: 1% 3% 1% 3%;
    }
    @media (max-width: 430px) {
      padding: 1% 3% 1% 3%;
    }
  }
  &__card {
    //display: flex;
    //align-items: center;
    background-color: white;
    width: 30%;
    height: 100%;
    margin: 1rem;
    padding: 3rem;
    border: "1px solid red";
    flex-direction: column;
    @media (max-width: 650px) {
      width: auto;
    }
    border-radius: 1rem;
    --tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    &__frontside {
      //background-color: #084c3f;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    &__maincard {
      background-color: white;
      width: 40%;
      margin: 1rem;
      padding: 3rem;
      flex-direction: column;
      @media (max-width: 650px) {
        width: auto;
      }
      border-radius: 1rem;
      --tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      background-color: $primary-light;
    }

    &__icon {
      font-size: 3rem;
      cursor: pointer;
    }

    //vector
    &__vector {
      width: 300px; /* set the width of the element */
      height: auto;
      //padding: 2%;
      //margin: 0 auto 8% auto; /* center the element horizontally */
      //width: 90%;
      //height: 70%;
      //align-items: center;
    }

    //this is for step1:
    &__font1 {
      font-size: 1.7rem;
      text-align: left;
      cursor: pointer;
    }

    //this is for preprocess
    &__font2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 20%;
      justify-content: space-around;
      align-items: center;
      font-size: 2.5rem;
      //text-align: center;
      font-weight: 900;

      //margin: 2%;
    }

    //this is for nature of engagement
    &__font3 {
      font-size: 1.5rem;
      text-align: left;
    }

    //this is for nature of online, 2weeks
    &__font4 {
      font-size: 2.1rem;
      text-align: left;
      font-weight: bold;
      margin-bottom: 3%;
    }

    //objectives
    &__font5 {
      background-color: $primary-dark;
      width: fit-content;
      color: white;
      padding: 2% 3% 2% 3%;
      font-size: 2rem;
      text-align: left;
      font-weight: 900;
      margin: 4% 0 4% 0;
    }

    &__font6 {
      font-size: 1.8rem;
      text-align: left;
    }

    &__font7 {
      text-align: left;
      font-weight: bold;
    }

    &__font8 {
      text-align: left;
      font-weight: bold;
      font-size: 2.5rem;
    }

    &__header {
      padding-top: 2%;
      padding-bottom: 2%;
      font-size: 4rem;
      text-align: left;
      font-weight: 700;
      line-height: 1;
      margin-top: 20%;

      @media (max-width: 650px) {
        padding-top: 5%;
        //width: 100%;
        text-align: center;
        margin-top: 0%;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
        margin-top: 0%;
      }
    }
    &__content {
      // padding-top: 2%;
      // padding-bottom: 2%;
      font-size: 1.9rem;
      text-align: left;
      font-weight: 500;
      color: gray;

      @media (max-width: 650px) {
        padding-top: 2%;
        font-size: 2rem;
        text-align: justify;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
      }
    }
    &__button {
      padding: 1rem;
      letter-spacing: 2px;
      //text-transform: uppercase;
      margin-top: 2rem;
      background-color: $primary-dark2;
      color: white;
      border: 1px solid $primary-dark;
      //width: 50%;
      font-size: 1.5rem;
      border-radius: 10px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 650px) {
        font-size: 1.8rem;
        //width: 60%;
        //margin-left: 20%;
      }
      @media (max-width: 430px) {
      }

      &:not(:last-child) {
        margin-right: 4rem;
      }
    }
    &__imageDiv {
      position: relative;
      width: 100%;
      //max-width: 100rem;
      //min-width: 60rem;
      display: flex;
      justify-content: center;
      //height: 50rem;
    }
    &__universityImg {
      width: 80%;
      height: 80%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
      @media (max-width: 430px) {
      }
    }
  }
}

.NewThirdsection {
  margin: 5%;
  position: relative;
  @media (max-width: 650px) {
    // margin-top: -10%;
  }

  &__firstSection {
    display: flex;
    justify-content: space-evenly;
    font-size: 2rem;
    height: 35rem;
    margin-top: 2%;
    @media (max-width: 600px) {
      flex-direction: column;
      height: 100%;
    }
    &__card {
      background-color: white;
      width: 40%;
      height: 100%;
      padding: 2rem;
      flex-direction: column;
      @media (max-width: 600px) {
        width: auto;
      }
      //border-radius: 1rem;
      //--tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      //box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      &__header {
        padding-top: 2%;
        padding-bottom: 2%;
        font-size: 3rem;
        text-align: left;
        font-weight: 500;
        line-height: 1;

        @media (max-width: 650px) {
          padding-top: 5%;
          width: 100%;
          text-align: center;
        }
        @media (max-width: 430px) {
          padding-top: 5%;
        }
      }
      &__content {
        margin-top: 6%;
        // padding-bottom: 2%;
        font-size: 1.5rem;
        text-align: left;
        font-weight: 500;
        color: gray;

        @media (max-width: 650px) {
          padding-top: 2%;
          font-size: 2rem;
          text-align: justify;
        }
        @media (max-width: 430px) {
          padding-top: 5%;
        }
      }
      &__button {
        padding: 1rem;
        margin-top: 4rem;
        background-color: $primary-dark2;
        color: white;
        border: 1px solid $primary-dark;
        width: 50%;
        font-size: inherit;
        justify-content: center;
        align-items: center;
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        @media (max-width: 650px) {
          font-size: 2.5rem;
          width: 60%;
        }
        @media (max-width: 430px) {
        }

        &:not(:last-child) {
          margin-right: 4rem;
        }
      }
      &__imageDiv {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        //height: 50rem;
      }
      &__universityImg {
        width: 90%;
        //height: auto;
        //object-fit: cover;
        @media (max-width: 650px) {
          width: 95%;
        }
        @media (max-width: 430px) {
        }
      }
    }
  }
}

//--------------------------------------------- Third Section ---------------------------------------------------//

.ThirdSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    &-button {
      font-size: 2rem;
      padding: 1.5rem;
      border: 1px solid $primary-dark2;
      border-radius: 1rem;
      background-color: $primary-dark2;
      color: white;
      font-family: inherit;
      font-weight: 100;
      --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 430px) {
        padding: 1rem;
      }

      &:not(:last-child) {
        margin-right: 4%;
      }

      &:hover {
        transform: translateY(-5px);
        transition: all 0.2s;
        background-color: white;
        color: $primary-dark;
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
      &:active {
        transform: translateZ(5px);
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
  }

  &__content {
    border: 1px solid $primary-dark;
    border-radius: 10px;
    width: 98%;
    height: 45rem;
    align-self: center;
    margin: 3rem 0;
    overflow-x: scroll;
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 650px) {
      border: none;
      flex-wrap: wrap;
      height: auto;
      text-align: center;
    }

    &-1 {
      //background-color: wheat;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @media (max-width: 650px) {
        width: 50%;
        padding: 2rem;
      }

      &__title {
        color: $primary-dark;
        font-size: 2rem;
        font-weight: 500;
      }

      &__info {
        color: $primary-light;
        font-size: 1.5rem;
        padding: 0 2rem;
        text-align: center;
      }

      &__img {
        height: 250px;
        width: 250px;

        @media (max-width: 1100px) {
          height: 200px;
          width: 200px;
        }
        @media (max-width: 900px) {
          height: 150px;
          width: 150px;
        }
      }

      &:hover {
        transform: translateY(-5px);
        transition: all 0.2s;
      }
    }
  }
}

//--------------------------------------------- Fourth Section //contact ---------------------------------------------------//
.Fourthsection {
  background-color: $primary-dark2;
  width: 94%;
  height: 70rem;
  border-radius: 10px;
  margin: 4rem 0;
  transform: translate(3%);
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    height: 100%;
    padding-bottom: 7%;
  }

  &__bgcolor {
    background-color: #32cbcb;
  }

  &__contactinfo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      width: auto;
    }

    &-info {
      width: 80%;
      height: 80%;
      color: white;
      padding: 10px 50px;
      font-family: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-hello {
        font-size: 3.6rem;
        padding: 1rem 0px;
      }
      &-tagline {
        font-size: 2rem;
        padding: 1rem 0;
      }
      &-discription {
        font-size: 1.5rem;
        color: #c0f0f0b6;
      }
      &-contact {
        display: flex;
        align-items: center;
        font-family: inherit;
        font-size: 1.5rem;
        margin: 1.3rem 0;
      }
      &-callnemail {
        color: white;
        text-decoration: none;
      }
      &-icon {
        font-size: 2rem;
        margin-right: 1rem;
      }
    }
  }

  &__contactform {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      width: auto;
      position: relative;
    }

    &-form {
      height: 85%;
      width: 80%;
      background-color: white;
      border-radius: 15px;
      padding: 5rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      --tw-shadow: 10px 10px 15px 10px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 900px) {
        height: 90%;
      }

      //------ form -------//
      &-allinput {
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 500;

        &-input {
          margin-bottom: 17px;
          display: flex;
          flex-direction: column;

          &-inputfield {
            font-size: 1.5rem;
            font-family: inherit;
            color: $primary-medium;
            outline: none;
            padding: 8px 10px;
            border: none;
            border-bottom: 2px solid $primary-superlight;
            &:hover {
              border-bottom: 2px solid $primary-dark2;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      &-button {
        margin-top: 4rem;
        margin-left: auto;
        width: 50%;
        cursor: pointer;
        padding: 1rem;
        font-size: 1.8rem;
        border: 1px solid $primary-dark2;
        border-radius: 1rem;
        background-color: $primary-dark2;
        color: white;
        font-family: inherit;
        font-weight: 100;
        --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        &:hover {
          transform: translateY(-5px);
          //color: black;
          transition: all 0.1s;
          //background-color: $primary-light;
          //color: $primary-dark;
          --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 10px 10px -5px rgba(0, 0, 0, 0.04);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
        &:active {
          transform: translateZ(5px);
          --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
      }
    }
  }
}

//--------------------------------------------- Fourth Section ---------------------------------------------------//

//--------------------------------------------- Our Team ---------------------------------------------------//

.foundersHeader {
  font-size: 4rem;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 2%;

  &__subHeader {
    text-align: center;
    font-size: 2rem;
  }
}

.founders {
  width: 80%;
  margin: 0 auto 4% auto;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 650px) {
    flex-wrap: wrap;
    height: auto;
  }

  &__card {
    background-color: white;
    width: 23%;
    //height: 100%;
    padding: 2rem;
    border-radius: 1rem;
    --tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    @media (max-width: 40rem) {
      width: 80%;
      margin: 2rem;
      height: 100%;
    }

    // @media (max-width: 650px) {
    //   width: 40%;
    //   margin: 2rem;
    //   height: 100%;
    // }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__section {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 2rem;
    }

    &__name {
      font-size: 1.8rem;
      font-weight: 700;
    }
    &__linkedin {
      width: 3rem;
    }

    &__img {
      width: 80%;
      height: 80%;
      border-radius: 50%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
    }
  }
}

// -------- nep

.NEPPage {
  display: flex;
  justify-content: space-evenly;
  font-size: 2rem;
  //height:0%;
  //margin: 0 0 0 0;

  @media (max-width: 650px) {
    flex-direction: column;
    height: 100%;
  }
  &__card {
    background-color: white;
    width: 50%;
    padding: 2rem;
    @media (max-width: 650px) {
      width: 80%;
      margin: auto;
    }
    //border-radius: 1rem;
    //--tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    //box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    &__subheader {
      padding-bottom: 2%;
      font-size: 2rem;
      text-align: left;
      font-weight: 900;
      line-height: 1;
      margin-top: 20%;

      @media (max-width: 650px) {
        padding-top: 5%;
        //width: 100%;
        text-align: center;
        margin-top: 0%;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
        margin-top: 0%;
      }
    }
    &__header {
      padding-bottom: 2%;
      font-size: 4rem;
      //text-align: left;
      //font-weight: 900;
      //line-height: 1;
      //margin-top: 10%;

      @media (max-width: 650px) {
        padding-top: 5%;
        //width: 100%;
        text-align: center;
        margin-top: 0%;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
        margin-top: 0%;
      }
    }
    &__content {
      // padding-top: 2%;
      // padding-bottom: 2%;
      font-size: 2rem;
      text-align: left;
      font-weight: 500;
      //color: gray;

      @media (max-width: 650px) {
        padding-top: 2%;
        font-size: 2rem;
        text-align: justify;
      }
      @media (max-width: 430px) {
        padding-top: 5%;
      }
    }
    &__button {
      padding: 1rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-top: 4rem;
      background-color: $primary-dark2;
      color: white;
      cursor: pointer;
      border: 1px solid $primary-dark;
      width: 50%;
      font-size: inherit;
      justify-content: center;
      text-align: center;
      border-radius: 8px;

      //align-items: center;
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      @media (max-width: 650px) {
        font-size: 2.5rem;
        width: 60%;
        margin-left: 20%;
      }
      @media (max-width: 430px) {
      }

      &:not(:last-child) {
        margin-right: 4rem;
      }

      &:hover {
        transform: translateY(-5px);
        transition: all 0.1s;
        //color: $primary-dark;
        --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
    &__imageDiv {
      position: relative;
      width: 100%;
      //max-width: 100rem;
      //min-width: 60rem;
      display: flex;
      justify-content: center;
      //height: 50rem;
    }
    &__logo {
      width: 20%;
      cursor: pointer;
      height: 90%;
      @media (max-width: 650px) {
        width: 40%;
        height: 80%;
      }
      @media (max-width: 430px) {
        width: 40%;
        height: 80%;
      }
    }
    &__studentImg {
      width: 100%;
      height: 80%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
      @media (max-width: 430px) {
      }
    }
    &__universityImg {
      width: 70%;
      height: 80%;
      //object-fit: cover;
      @media (max-width: 650px) {
        width: 95%;
        height: 100%;
      }
      @media (max-width: 430px) {
      }
    }
  }
}

// ------------------------------ NEP -----------------------------

.NEP {
  &__bulletpoints {
    color: gray;
    font-size: 1.8rem;
    margin-top: 4%;
    margin-bottom: 10%;
  }
  &_card {
    background-color: white;
    width: 30%;
    height: 50%;
    padding: 4rem;
    border-radius: 1rem;
    --tw-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    @media (max-width: 650px) {
      width: 40%;
      margin: 2rem;
      height: 100%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &-icon {
      font-size: 6rem;
      color: $primary-medium;
    }

    &-title {
      font-size: 1.7rem;
      font-weight: 700;
      @media (max-width: 650px) {
        padding-bottom: 1rem;
      }
    }

    &-info {
      font-size: 1.5rem;
      color: gray;
      text-align: center;
    }

    &:hover {
      transition: all 0.2s;
      --tw-shadow: 0 25px 50px -3px rgba(0, 0, 0, 0.25);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }

  &__button {
    padding: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 50%;
    background-color: $primary-dark2;
    color: white;
    cursor: pointer;
    border: 1px solid $primary-dark;
    width: 50%;
    font-size: inherit;
    justify-content: center;
    text-align: center;
    border-radius: 8px;

    //align-items: center;
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    @media (max-width: 650px) {
      font-size: 2.5rem;
      width: 60%;
      margin-left: 20%;
    }
    @media (max-width: 430px) {
    }

    &:not(:last-child) {
      margin-right: 4rem;
    }

    &:hover {
      transform: translateY(-5px);
      transition: all 0.1s;
      //color: $primary-dark;
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
}

//--------------------------------------------- About us ---------------------------------------------------//

.aboutus {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__img {
    background-image: url(../Images/bg.jpg);
    background-size: cover;
    height: 400px;
    color: white;
    background-color: transparent;
    text-align: center;
    width: 100%;
  }

  &__para {
    color: black;
    font-weight: 900;
    font-size: 2.5rem;
    width: 100%;

    @media (max-width: 650px) {
      font-size: 2.5rem;
      margin-bottom: 30%;
    }
  }
}

// -------------- purpose --------------

.purpose {
  font-size: 4rem;
  text-align: center;
  margin-top: 6%;

  &__subHeader {
    text-align: center;
    font-size: 2rem;
    margin: 2%% 5% 5% 5%;
  }
}
